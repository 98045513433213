import { FC } from 'react'
import { Tabs } from '@mui/material'
import { TabList } from './styles'

export type TPageTab = { id: number; label: string };

export const PageTabs: FC<{
  tabs: TPageTab[];
  value?: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ tabs, value, onChange }) => {
  return (
    <Tabs
      onChange={onChange}
      value={value}
      TabIndicatorProps={{ style: { display: 'none' } }}
    >
      {tabs.map(({ id, label }) => (
        <TabList disableRipple key={id} active={id === value} label={label} />
      ))}
    </Tabs>
  )
}
