export type TCompany = {
  id: string;
  name: string;
  isActive: boolean;
  purpose: string;
  picture: string;
};

export type TOfficer = {
  id: string;
  email: string;
  phone: string;
  companyId: string[];
};

export type IS3UploadRequest = {
  key: string;
  url: string;
};
export interface IApplicationKeys {
  apiOrigin: string;
  widgetUrl: string;
  publicKey: string;
  appPublicKey: string;
  appToken: string;
  webhookSecretKey: string;
}
export interface IGeneratedKeys extends IApplicationKeys {
  privateKey: string;
}
export interface IViewKeys extends IApplicationKeys {
  webhookUrl: string;
}
export const AUTH_COMPANY_ID = "company_id";
export const AUTH_COMPANY_NAME = "company_name";
export const AUTH_COMPANY_PURPOSE = "company_purpose";

export type ICompanyActionModeType = "create" | "edit" | "";
export type IOfficerActionModeType = "create" | "edit" | "onlyEdit";
