import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FFInputFile } from "../FFInputFile";
import { FileBlock } from "../FileBlock";

export type Tprops = {
  namePrefix: string;
  label?: string;
  subtitle?: string;
  InputComponent?: any;
  width?: string;
  height?: string;
  icon?: any;
  multiple?: boolean;
  validate?: (value?: string | undefined) => string | undefined;
  handleAddInput?: (e: Event) => void;
  handleChange?: (e: any) => void;
  value: any;
  handleDeleteInput?: (e: Event, cb?: () => void) => void;
  key?: any;
  document?: any;
  required?: boolean;
  isDeleteBan?: boolean;
  isMaximumInputs?: boolean;
};

export enum FileType {
  "application/pdf" = "PDF",
  "image" = "Image",
}

export const FileInput: FC<Tprops> = ({
  namePrefix,
  label,
  subtitle,
  width,
  height,
  icon,
  multiple,
  handleAddInput,
  handleChange,
  handleDeleteInput,
  document,
  required,
  isDeleteBan,
  isMaximumInputs,
  value,
}) => {
  const [currentFileType, setCurrentFileType] = useState<FileType>();

  const previewImg = useMemo(() => {
    return (
      (value && typeof value === "object" && URL.createObjectURL(value)) || null
    );
  }, [value]);

  useEffect(() => {
    const getContentType = async () => {
      if (previewImg) {
        try {
          const response = await fetch(previewImg);
          const contentType = response.headers.get("content-type");
          if (contentType === "application/pdf") {
            setCurrentFileType(FileType["application/pdf"]);
            return;
          }
          setCurrentFileType(FileType.image);
        } catch (e) {
          console.log("e", e);
        }
      }
    };

    getContentType();
  }, [previewImg]);

  const handleDelete = useCallback(() => {
    handleChange?.(null);
  }, [handleChange]);

  const inputLabel = useMemo(() => {
    return (
      (value?.inputData &&
        Object.entries(value?.inputData)
          ?.map((entrie: [string, any]) => entrie[1]?.name)
          ?.join("\n")) ??
      label
    );
  }, [value?.inputData, label]);

  return (
    <FileBlock
      handleChange={handleChange}
      isMaximumInputs={isMaximumInputs}
      isDeleteBan={isDeleteBan}
      required={required}
      currentFileType={currentFileType}
      document={document ?? {}}
      width={width}
      height={height}
      label={inputLabel}
      subtitle={inputLabel === label ? subtitle : undefined}
      icon={icon}
      namePrefix={namePrefix}
      InputComponent={FFInputFile}
      value={value}
      multiple={multiple}
      handleDelete={handleDelete}
      handleAddInput={handleAddInput}
      handleDeleteInput={handleDeleteInput}
    />
  );
};
