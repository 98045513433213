import { styled, Stack } from "@mui/material";

export const SideBarWrapper = styled(Stack)`
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 100;
  padding: 22px 16px;
  width: 100px;
  height: calc(100vh - 64px);
  background: #ffffff;
  backdrop-filter: blur(20px);
  @media screen and (max-width: 850px) {
    display: none;
  }
`;
