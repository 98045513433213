import { styled, Box } from "@mui/system";

export const LeftColumn = styled(Box)`
  width: 60%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const RightColumn = styled(Box)`
  flex-grow: 0;
  max-width: 400px;
  @media screen and (max-width: 850px) {
    margin-top: 40px;
  }
`;
