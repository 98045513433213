import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const HeaderBox = styled(Box)`
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  padding-left: 160px;
  padding-right: 60px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 10;
  @media screen and (max-width: 850px) {
    position: static;
    max-height: 20px;
    padding-left: 20px;
    padding-right: 20px;
    top: 25px;
  }
`;

export const PageLayoutWrapper = styled(Box)`
  padding-top: 40px;
  width: 100%;
`;

export const Content = styled("div")`
  position: relative;
`;

export const Loader = styled("div")`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  height: 250,
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Empty = styled("div")`
  width: 100%;
  height: 250,
  display: flex;
  justify-content: center;
  align-items: center;
`;
