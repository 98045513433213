import {
  Button,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  styled,
} from '@mui/material';

export const DialogCustomTitle = styled(DialogTitle)`
  padding: 32px 32px 0;
  text-align: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #1c1f21;
`;

export const DialogCustomContent = styled(DialogContent)`
  padding: 0 32px 32px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1f21;
`;

export const DialogCustomButton = styled(Button)`
  padding: 16px 24px;
  width: 164.5px;
  height: 54px;
  border-radius: 12px;
  text-transform: capitalize;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  margin-top: 16px;
  resize: none;
  width: 100%;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #dee0e4;
  transition: all 0.1s;

  &:focus {
    border-color: #1976d2;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`;
