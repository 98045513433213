import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { TextField } from "mui-rff";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Container, Typography } from "@mui/material";
import { useRequestSms } from "../../auth/useRequestSms";
import { useLogin } from "../../auth/useLogin";
import { Box } from "@mui/system";
import { ResendSms } from "./ResendSms";
import { filterInput, numbersRegExp } from "../../utils/validate";
import { parseSignInError } from "../../utils/parseSignInError";

type FormValues = {
  phoneNumber?: string;
  code?: string;
};

export const SigninScreen = () => {
  const [step, setStep] = useState<"phone" | "code">("phone");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [smsResult, smsLoading, smsError, smsRequest] = useRequestSms();
  const [, loginLoading, loginError, loginRequest] = useLogin();

  useEffect(() => {
    if (smsResult) {
      setStep("code");
    }
  }, [smsResult]);

  const onSubmit = useCallback(
    (formData: FormValues) => {
      if (step === "phone") {
        if (formData.phoneNumber) {
          let phoneNumber = "+" + formData.phoneNumber.split("+").join("");
          smsRequest(phoneNumber);
          setPhoneNumber(phoneNumber);
        }
      } else if (step === "code") {
        if (phoneNumber && formData.code && smsResult?.salt) {
          loginRequest(phoneNumber, formData.code, smsResult.salt);
        }
      }
    },
    [smsResult, smsRequest, loginRequest, setPhoneNumber, phoneNumber, step]
  );

  const validate = useCallback(
    (formData: FormValues) => {
      const errors: ValidationErrors = {};

      if (step === "phone" && !formData.phoneNumber) {
        errors.phoneNumber = true;
      }

      if (step === "code" && !formData.code) {
        errors.code = true;
      }

      return errors;
    },
    [step]
  );

  const resendSms = useCallback(() => {
    if (phoneNumber) {
      return smsRequest(phoneNumber);
    }
  }, [smsRequest, phoneNumber]);

  const error = smsError || loginError;

  return (
    <Container maxWidth='sm'>
      <Box
        height='100vh'
        pb={5}
        pt={5}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Box minHeight={400}>
          <Typography variant='h4'>Sign in to One Board</Typography>
          <Box mb={2} />
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                {step === "phone" && (
                  <TextField
                    placeholder='+9998887766'
                    label='Phone Number'
                    name='phoneNumber'
                    required={true}
                    size='small'
                    margin='normal'
                    inputProps={{
                      onChange: (e) =>
                        filterInput(
                          (e.target as HTMLTextAreaElement).value,
                          numbersRegExp,
                          setPhoneNumber
                        ),
                    }}
                  />
                )}
                {step === "code" && (
                  <TextField
                    label='SMS Code'
                    name='code'
                    required={true}
                    size='small'
                    margin='normal'
                    autoFocus
                  />
                )}
                <Box mt={3} />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  disabled={smsLoading || loginLoading}
                >
                  Continue
                </Button>
                <Box mt={2} />
                {step === "code" && <ResendSms onResend={resendSms} />}
                <Box mt={2} />
                {error !== null && (
                  <Alert severity='error'>
                    {parseSignInError(
                      error.toString(),
                      "The code has expired. Please resend it and enter the new one."
                    )}
                  </Alert>
                )}
              </form>
            )}
          />
        </Box>
      </Box>
    </Container>
  );
};
