import { FC, useEffect } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FileLabelInput,
  FileSubLabelInput,
  ImageFile,
  FileBlockWrapper,
  ErrorFileLabel,
  FileLabelInputLine,
  FileLabelSubtitle,
  DialogCustomLabel,
} from "./styles";
import { UploadedDoc } from "../../../../assets/icons";
import { useFileLoading } from "../../hooks/useFileLoading";
import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
  value: any;
  namePrefix: string;
  label?: string;
  subtitle?: string;
  InputComponent?: any;
  currentFileType?: string;
  width?: string;
  height?: string;
  icon?: string;
  document?: any;
  multiple?: boolean;
  required?: boolean;
  isDeleteBan?: boolean;
  maxSize?: number;
  extensions?: string[];
  handleOpenModal?: (e: any) => void;
  handleCloseModal?: () => void;
  handleRetakeModal?: () => void;
  handleDelete?: () => void;
  isMaximumInputs?: boolean;
  handleAddInput?: (e: any) => void;
  handleChange?: (e: any) => void;
  handleDeleteInput?: (e: Event, cb?: () => void) => void;
};

export const FileBlock: FC<Props> = ({
  width,
  height,
  label,
  subtitle,
  icon,
  document: fileDoc,
  currentFileType,
  namePrefix,
  value,
  InputComponent,
  multiple = false,
  handleOpenModal,
  handleCloseModal,
  handleRetakeModal,
  handleDelete,
  maxSize,
  extensions,
  required = false,
  isDeleteBan = false,
  isMaximumInputs = false,
  handleAddInput,
  handleDeleteInput,
  handleChange,
}) => {
  const [onFileLoad, onDelete, error] = useFileLoading(
    value,
    handleDelete,
    maxSize,
    extensions,
    handleChange
  );
  const previewImg =
    (value && typeof value === "object" && URL.createObjectURL(value)) || null;

  const trimFileName = (name: string | undefined) => {
    if (name === undefined) {
      return "";
    }
    if (name.length > 24) {
      return (
        name.slice(0, 9) + "..." + name.slice(name.length - 9, name.length)
      );
    }
    if (name.length <= 24) {
      return name;
    }
    return "";
  };

  const link = typeof value === "string" && value;
  const isPdf = value?.type === "application/pdf" || currentFileType === "PDF";
  const isUploaded = value || link;
  const fileName: string = trimFileName(value?.name);

  const status = isUploaded || link ? "success" : "pending";

  useEffect(() => {
    const inputOrderNumber = Number(namePrefix.split("_")[3]);
    if (
      !isUploaded &&
      namePrefix.includes("FINANCIAL_INFORMATION_DOCUMENT") &&
      inputOrderNumber > 1
    ) {
      const newInput = document.getElementById(namePrefix);
      newInput?.click();
    }
  }, [namePrefix, isUploaded]);

  return (
    <Box width='100%'>
      <FileBlockWrapper
        status={status}
        width={width}
        height={height}
        onClick={handleOpenModal}
        htmlFor={namePrefix}
      >
        {(icon || isUploaded) && (
          <ImageFile
            src={
              isUploaded && isPdf
                ? UploadedDoc
                : previewImg
                ? previewImg
                : link
                ? link
                : icon
            }
          />
        )}
        <Stack flexDirection='column' minWidth={0}>
          {label && (
            <FileLabelInput status={status}>
              {label
                .split(/\r?\n/)
                .slice(0, 3)
                .map((line, index) => (
                  <FileLabelInputLine key={index}>{line}</FileLabelInputLine>
                ))}
              {label.split(/\r?\n/).length > 3 && <>...</>}
            </FileLabelInput>
          )}
          {subtitle && <FileLabelSubtitle>{subtitle}</FileLabelSubtitle>}
          {(isUploaded || link) && (
            <FileSubLabelInput isActive={isUploaded || link}>
              Uploaded
              <span style={{ color: "#1C1F21", marginLeft: "10px" }}>
                {fileName || currentFileType}
              </span>
            </FileSubLabelInput>
          )}
        </Stack>
        {InputComponent && (
          <InputComponent
            required={required}
            onChange={(e: any) => onFileLoad(e, handleCloseModal)}
            accept='.jpeg, .jpg, .png, .bmp'
            name={`${namePrefix}.inputData`}
            id={namePrefix}
            style={{ zIndex: "-1", position: "absolute", opacity: "0" }}
            multiple={multiple}
          />
        )}
        {(isUploaded || previewImg || link) && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translate(0,-50%)",
              right: "16px",
            }}
          >
            {!InputComponent && (
              <IconButton
                sx={{ color: "#8D959F" }}
                size='small'
                onClick={handleRetakeModal}
              >
                <RefreshTwoToneIcon />
              </IconButton>
            )}
            {(isUploaded || previewImg) && !(isDeleteBan || link) && (
              <IconButton
                sx={{ color: "#8D959F" }}
                size='small'
                onClick={(e: any) => {
                  if (handleDeleteInput) handleDeleteInput(e);
                  onDelete(e, fileDoc);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {(link || isDeleteBan) && (
              <DialogCustomLabel variant='icon' htmlFor={namePrefix}>
                <AutorenewIcon />
              </DialogCustomLabel>
            )}
          </Box>
        )}
        {isUploaded && !isMaximumInputs && handleAddInput ? (
          <AddIcon
            color='primary'
            onClick={handleAddInput}
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              top: "50%",
              right: -35,
              fontSize: 22,
              border: "1px solid #1976d2",
              borderRadius: "50%",
            }}
          />
        ) : null}
        {/* {handleDeleteInput ? (
          <RemoveIcon
            color='primary'
            onClick={(e: any) =>
              handleDeleteInput(e, () => onDelete(e, fileDoc))
            }
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              top: "50%",
              right: handleAddInput ? -70 : -35,
              fontSize: 22,
              border: "1px solid #1976d2",
              borderRadius: "50%",
            }}
          />
        ) : null} */}
      </FileBlockWrapper>
      {error && <ErrorFileLabel>{error}</ErrorFileLabel>}
    </Box>
  );
};
