import { FC } from 'react';
import { ImageList, ImageListItem, Stack } from '@mui/material';
import { TDocumentData, TDocumentInfo } from '../../models';
import { FileContent } from '../FileContent';
import { DocumentImgWrapper, Label } from './styles';

export const DocumentImgCard: FC<{
  fileItems: TDocumentData[];
  requestId: string;
  document: TDocumentInfo;
}> = ({ fileItems, requestId, document }) => {
  return (
    <ImageList cols={1}>
      {fileItems.map((dataItem) => (
        <ImageListItem key={dataItem.dataId} sx={{ marginBottom: '24px' }}>
          <DocumentImgWrapper
            flexDirection='row'
            alignItems='flex-start'
            gap='40px'
          >
            <FileContent
              requestId={requestId}
              documentId={document.documentId}
              fileId={dataItem.dataId}
              dataLink={dataItem.dataLink}
            />
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap='8px'
              sx={{
                width: 'calc(50% - 40px)',
              }}
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Label>ID {dataItem.id}</Label>
              </Stack>
            </Stack>
          </DocumentImgWrapper>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
