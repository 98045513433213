import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Form } from "react-final-form";
import { DatePicker, Select, SelectData } from "mui-rff";
import { FilledTextArea } from "../../../../components/FilledTextArea";
import { titleCase } from "../../../../utils";
import { FieldSet } from "../../../ui/components/FieldSet";
import { TDocumentData } from "../../models";
import { SchemeField } from "../DocumentInfoCard";
import scheme from "../DocumentInfoCard/scheme.json";
import { Grid } from "@mui/material";
import countries from "../../data/countries.json";
import { FinancialInformation } from "./FinancialInformation";
import { Occupation } from "./Occupation";
import { SecondNationality } from "./SecondNationality";

export type Props = {
  data: TDocumentData;
  setDataFields: Dispatch<SetStateAction<{}>>;
  dataFields: object;
  allDisabled?: boolean;
};

const countrySelectData: SelectData[] = countries.map((x) => ({
  label: x.name,
  value: x.code,
}));

const BinaryContentField = memo(
  (props: {
    item: SchemeField;
    fields: any;
    setFields: any;
    data: any;
    dataId: string;
    allDisabled?: boolean;
  }) => {
    const { item, fields, setFields, data, dataId, allDisabled } = props;

    const [name, config] = useMemo(() => {
      if (typeof item === "string") {
        return [item, { type: "string" }];
      }
      if (Array.isArray(item)) {
        return [item[0], item[1]];
      }
      return ["", { type: "string" }];
    }, [item]);

    useEffect(() => {
      if (data) {
        setFields({ ...data });
      }
    }, [data, name, setFields]);

    const label = useMemo(() => {
      return config.label || titleCase(name);
    }, [name, config]);

    const onChange = (e: any) => {
      const newFields = { ...fields, [name]: e.target.value };
      if (!e.target.value) {
        delete newFields[name];
      }
      setFields(newFields);
    };

    const country = useMemo(() => {
      return (
        countries.find((country) => country.name === fields[name])?.code ||
        fields[name]
      );
    }, [fields, name]);

    if (config.type === "date") {
      return (
        <DatePicker
          disabled={allDisabled}
          name={name}
          label={label}
          inputFormat='dd-MM-yyyy'
        />
      );
    }

    if (dataId === "Occupation") {
      return (
        <Occupation
          fields={fields}
          label={label}
          name={name}
          onChange={onChange}
          disabled={allDisabled}
        />
      );
    }
    if (dataId === "FinancialInformation") {
      return (
        <FinancialInformation
          fields={fields}
          label={label}
          name={name}
          onChange={onChange}
          disabled={allDisabled}
        />
      );
    }
    if (name === "middle_name") {
      return (
        <SecondNationality
          fields={fields}
          label={label}
          name={name}
          onChange={onChange}
          disabled={allDisabled}
        />
      );
    }
    if (
      name === "country" ||
      name === "country_of_birth" ||
      name === "nationality"
    ) {
      return (
        <Select
          variant='filled'
          name={name}
          label={label}
          value={country ?? ""}
          data={countrySelectData}
          inputProps={{
            onChange,
          }}
          disabled={allDisabled}
        />
      );
    }

    return (
      <FilledTextArea
        value={fields[name]}
        name={name}
        label={label}
        InputProps={{
          onChange,
        }}
        disabled={allDisabled}
      />
    );
  }
);

export const BinaryContent = memo((props: Props) => {
  const {
    data: { dataId, data },
    setDataFields,
    dataFields,
    allDisabled,
  } = props;

  const onSubmit = useCallback(() => {}, []);

  const itemScheme =
    (scheme.dataTypes[dataId as keyof typeof scheme.dataTypes] as any) || null;

  return (
    <div>
      {itemScheme && (
        <Form
          initialValues={data}
          onSubmit={onSubmit}
          render={() => (
            <Grid direction='column' container>
              <FieldSet>
                {itemScheme.fields.map((field: SchemeField) => {
                  return (
                    <>
                      <BinaryContentField
                        allDisabled={allDisabled}
                        data={data}
                        fields={dataFields}
                        setFields={setDataFields}
                        item={field}
                        dataId={dataId}
                      />
                    </>
                  );
                })}
              </FieldSet>
            </Grid>
          )}
        ></Form>
      )}
    </div>
  );
});
