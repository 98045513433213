import { useCallback, useEffect, useState } from "react";
import { sendToVerificationRequest } from "../network";

export const useSendToVerification = (
  id?: string
): [
  any | null,
  boolean,
  Error | null,
  (requestId?: string, cb?: () => void) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (requestId?: string, cb?: () => void) => {
      setIsLoading(true);
      setError(null);

      requestId = requestId || id;

      if (requestId) {
        try {
          const response = await sendToVerificationRequest(requestId);
          setResult(response);
          cb?.();
        } catch (e) {
          if (e instanceof Error) {
            setError(e);
          } else {
            setError(new Error("Unknown error"));
          }
        }
      } else {
        setError(new Error("ID is not provided"));
      }

      setIsLoading(false);
    },
    [id]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(null);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return [result, isLoading, error, request];
};
