import { useCallback, useEffect} from 'react';
import { Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Alert } from '@mui/material';
import { useAppCreate } from '../../hooks/useAppCreate';
import { TextField } from 'mui-rff';

export type Props = {
  onClose: (success: boolean) => void;
  open: boolean;
};

type FormValues = {
  name?: string;
};

export const CreateAppDialog = (props: Props) => {
  const { onClose, open } = props;
  const [result, loading, error, request] = useAppCreate();

  useEffect(() => {
    if (result) {
      onClose(true);
    }
  }, [result, onClose]);

  const handleCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const onSubmit = useCallback((formData: FormValues) => {
    const { name } = formData;
    if (name) {
      request({
        name,
      });
    }
  }, [request]);

  const validate = useCallback((formData: FormValues) => {
    const errors: ValidationErrors = {};

    if (!formData.name) {
      errors.name = true;
    }

    return errors;
  }, []);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      id='create-app-dialog'
      keepMounted
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle>Create app</DialogTitle>
            <DialogContent dividers>
              <TextField
                label='App name'
                name='name'
                required={true}
                size='small'
                margin='normal'
              />
              {error !== null && (
                <Alert severity='error'>{error.toString()}</Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancel} disabled={loading}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={loading}>
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};
