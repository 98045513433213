import { memo, useCallback, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import PageLayout from '../../../../components/PageLayout';
import { useAppList } from '../../hooks/useAppList';
import { CreateAppDialog } from '../../components/CreateAppDialog';
import { CreateButton, DataGrid, FunctionViewField, ListContainer, TextViewField, TId } from '../../../ui';
import { TApp } from '../../models';
import { useNavigate } from 'react-router-dom';

export const AppListScreen = memo(() => {
  const [data, loading, error, reload] = useAppList();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenCreate = useCallback(() => {
    setIsCreateOpen(true);
  }, [setIsCreateOpen]);

  const handleCloseCreate = useCallback(
    (success: boolean) => {
      setIsCreateOpen(false);
      if (success) {
        reload();
      }
    },
    [setIsCreateOpen, reload]
  );

  const onRowClick = useCallback(
    (id: TId) => {
      navigate(`/apps/${id}`);
    },
    [navigate]
  );

  return (
    <PageLayout
      title='Apps'
      loading={loading}
      empty={error === null && (data === null || data.items.length === 0)}
      actions={
        <CreateButton onClick={handleOpenCreate}>New app</CreateButton>
      }
    >
      {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      <ListContainer data={data?.items} isLoading={loading}>
        <DataGrid onRowClick={onRowClick}>
          <FunctionViewField
            label='Name'
            source='name'
            render={(record: TApp) => (
              <Box>
                <Typography variant='h6'>{record?.name}</Typography>
                <Typography variant='body2'>ID: {record?.id}</Typography>
              </Box>
            )}
          />
          <TextViewField label='Public Key' source='publicKey' emptyText='—' />
        </DataGrid>
      </ListContainer>
      <CreateAppDialog open={isCreateOpen} onClose={handleCloseCreate} />
    </PageLayout>
  );
});
