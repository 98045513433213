import { Box, Toolbar } from "@mui/material";
import { AppCompanyMenu } from "../AppCompanyMenu";
import { AppUserMenu } from "../AppUserMenu";
import { HeaderWrapper } from "../AppCompanyMenu/styles";
import { useWidth } from "../../modules/app/hooks/useWidth";
import { SideBarIcons } from "../SideBar/components/SideBarIcons";

export const AppHeader = () => {
  const width = useWidth();

  return (
    <HeaderWrapper position={["xs", "sm"].includes(width) ? "static" : "fixed"}>
      <Toolbar variant='dense'>
        {["xs", "sm"].includes(width) ? <SideBarIcons direction='row' /> : null}
        <Box sx={{ flexGrow: 1 }}>
          <AppCompanyMenu />
        </Box>
        <AppUserMenu />
      </Toolbar>
    </HeaderWrapper>
  );
};
