import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";
import { AppLayout } from "../../components/AppLayout";

export const AppContainer: FC = () => {
  const { authenticated, authorizedCompanyId } = useAuth();

  if (!authenticated) {
    return <Navigate to='/auth' />;
  }

  if (!authorizedCompanyId) {
    return <Navigate to='/selectcompanies' />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};
