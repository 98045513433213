import { memo, useCallback, useMemo, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { TDocument } from "../../models";
import { BinaryContent } from "../BinaryContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DocumentAccordeon } from "./styles";
import { DocumentImgCard } from "../DocumentImgCard";
import { titleCase } from "../../../../utils";
import { isObjectsEqual } from "../../../../utils/compareObj";
import { useUploadDocumentData } from "../../hooks/useAddVerificationRequest";

export * from "./types";

export type Props = {
  item: TDocument;
  requestId: string;
  request: any;
};

export const DocumentInfoCard = memo((props: Props) => {
  const {
    requestId,
    item: { document, data },
    request: getDocs,
  } = props;
  const title = document.documentType;
  const [dataFields, setDataFields] = useState({});
  const [result, isLoading, error, request] = useUploadDocumentData(
    data[0].document,
    data[0].dataId,
    dataFields
  );

  const [binaryItems, fileItems] = useMemo(() => {
    const binary: typeof data = [];
    const file: typeof data = [];
    data.forEach((x) => {
      if (x.type === "binary") {
        binary.push(x);
      } else if (x.type === "file") {
        file.push(x);
      }
    });

    return [binary, file];
  }, [data]);

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      request();
      if (!error) getDocs();
    },
    [request, getDocs, error]
  );

  const binaryData = useMemo(
    () => data.find((item) => item.type === "binary")?.data,
    [data]
  );

  return (
    <DocumentAccordeon disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Grid container alignItems='center' spacing={3}>
          <Grid item>
            <Typography
              variant='h6'
              sx={{
                fontWeight: "800",
                fontSize: "20px",
                lineHeight: "28px",
                letterSpacing: "0.5px",
                color: "#1C1F21",
                textTransform: "capitalize",
              }}
            >
              {titleCase(title)}
            </Typography>
          </Grid>
          {/* {binaryData && !isObjectsEqual(binaryData!, dataFields) ? (
            <Grid item>
              <Button color='error' onClick={(e) => onSubmit(e)}>
                Save
              </Button>
            </Grid>
          ) : null} */}
          {error !== null && (
            <Grid item>
              <Alert severity='error'>{error.toString()}</Alert>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ marginTop: "8px" }}>
        {binaryItems.map((dataItem) => (
          <BinaryContent
            // allDisabled
            dataFields={dataFields}
            setDataFields={setDataFields}
            data={dataItem}
          />
        ))}
        {fileItems.length > 0 && (
          <DocumentImgCard
            fileItems={fileItems}
            requestId={requestId}
            document={document}
          />
        )}
      </AccordionDetails>
    </DocumentAccordeon>
  );
});
