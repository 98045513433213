import { useState } from 'react'

export const useActiveTabs = () => {
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setActiveTab(newValue)
  }

  return { activeTab, handleChange }
}
