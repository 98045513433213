import { styled, Box, Button, Stack } from '@mui/material'

export const AddNewCompany = styled(Button)`
  position: relative;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2968ef;
`

export const DataBlock = styled(Stack)`
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #7f8fa0;
  }
`

export const LockedIconBlock = styled(Box)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
`
