import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { StatusLabel, StatusBlock } from "./styles";
import { getStatusIcon, statusesModel } from "../../utils/getStatus";
import { getLocaleStringdDate } from "../../../../utils/dateFormatt";

export const ApplicantSubtitle: FC<{
  status: string;
  deletedAt: string | null;
}> = ({ status, deletedAt }) => {
  return (
    <>
      <StatusBlock sx={{ bgcolor: statusesModel[status]?.bgColor }}>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          position='relative'
        >
          <Box position='absolute' top='0' left='0'>
            {getStatusIcon(status)}
          </Box>
          <StatusLabel
            variant='body2'
            sx={{ color: statusesModel[status]?.textColor }}
          >
            {statusesModel[status]?.name}{" "}
            {deletedAt ? "At " + getLocaleStringdDate(deletedAt) : ""}
          </StatusLabel>
        </Stack>
      </StatusBlock>
    </>
  );
};
