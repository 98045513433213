import type { InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: any;
  onChange: (e: any) => void;
}

export const FFInputFile = ({ name, onChange, value, ...props }: Props) => (
  <input
    type='file'
    onChange={onChange} // instead of the default target.value
    value={value}
    {...props}
  />
);
