import { Grid, styled } from "@mui/material";

export const CardTitleGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 850px) {
    margin-top: -80px;
    margin-bottom: 10px;
    gap: 0;
    flex-grow: 0;
  }
`;
