import { Accordion, styled } from "@mui/material";

export const DocumentAccordeon = styled(Accordion)`
  margin: 12px 0;
  padding: 20px 32px;
  background: #ffffff;
  border-radius: 14px !important;
  border: none !important;
  &:first-child {
    margin-top: 0;
  }
  &::before {
    content: "";
    display: none;
  }
`;
