import { FC, memo } from 'react'
import { Stack, Box, CircularProgress } from '@mui/material'
import { CompanyAuthButton } from '../CompanyAuthButton'
import { DataBlock } from './styles'

export const MenuCompanies: FC<{
  action: React.ReactNode
  data: any
  isLoading: boolean
}> = memo(({ action, data, isLoading }) => {
  return (
    <Stack
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      width='100%'
      marginTop='16px'
    >
      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <DataBlock
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-start'
          width='100%'
          maxHeight='344px'
          gap='12px'
        >
          {data?.items.length > 0 &&
            data.items.map((item: any) => (
              <CompanyAuthButton item={item} key={item.id} />
            ))}
        </DataBlock>
      )}
      <Box margin='16px 0'>{action && action} </Box>
    </Stack>
  )
})
