import { FC, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { HeadButton } from '../../../../components/HeadButtons/styles';
import { ApplicantLinkIcon } from '../../../../components/MuiIcons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WIDGET_URL } from '../../../../constants';

export const GenerateLinkButton: FC<{
  appPublicKey: string;
  id: string | undefined;
}> = ({ appPublicKey, id }) => {
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 1000);
    }
  }, [isCopy]);

  return (
    <>
      <CopyToClipboard
        text={`${WIDGET_URL}/?appPublicKey=${appPublicKey}&verificationRequestId=${id}`}
        onCopy={() => {
          setIsCopy(true);
        }}
      >
        <Tooltip
          arrow
          classes={{ arrow: 'arrow', tooltip: 'tooltip' }}
          placement='bottom'
          title={isCopy ? 'Copied' : 'Click to copy'}
          onClick={(event) => event.stopPropagation()}
        >
          <HeadButton
            variant='text'
            startIcon={<ApplicantLinkIcon />}
            sx={{
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                position: 'relative',
                top: '2px',
              },
            }}
          >
            Generate applicant link
          </HeadButton>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};
