import { TextField } from 'mui-rff'

export const FilledTextArea = TextField;

// export const FilledTextArea = styled((props: TextFieldProps) => (
//   <TextField
//     InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiFilledInput-root': {
//     overflow: 'hidden',
//     background: '#F4F4F4',
//     borderRadius: '12px',
//     // '&:hover': {
//     // },
//     // '&.Mui-focused': {
//     // },
//   },
//   '& .MuiInputLabel-root': {
//     fontWeight: '700',
//     fontSize: '12px',
//     lineHeight: '16px',
//     letterSpacing: '0.5px',
//     color: '#8D959F',
//   },
// }))
