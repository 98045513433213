import React, { createContext, useContext, useState } from 'react';
import { TListParamsState, useListParamsState } from '../../../ui/hooks';
import { TRecord, TId } from '../../../ui/models';

export interface ListContextValue extends TListParamsState {
  data: TRecord[] | null;
  isLoading?: boolean;
  selectedIds: TId[];
  setSelectedIds: (ids: TId[]) => void;
}

export const ListContext = createContext<ListContextValue>(
  {} as ListContextValue
);

export type TListContainerProps = React.PropsWithChildren<{
  data: TRecord[] | null;
  isLoading?: boolean;
}>;

export const ListContainer = (props: TListContainerProps) => {
  const { children, data, isLoading } = props;
  const { query, from, size, sort, setQuery, setFrom, setSize, setSort } =
    useListParamsState();
  const [selectedIds, setSelectedIds] = useState<TId[]>([]);

  return (
    <ListContext.Provider
      value={{
        data,
        isLoading,
        query,
        from,
        size,
        sort,
        selectedIds,
        setQuery,
        setFrom,
        setSize,
        setSort,
        setSelectedIds,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const useListContext = (): ListContextValue => {
  return useContext<ListContextValue>(ListContext);
};