import { FilledTextArea } from "../../../../components/FilledTextArea";

export const FinancialInformation = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
}) => {
  const { onChange, fields, name, label, disabled } = props;

  return (
    <FilledTextArea
      multiline={true}
      name={name}
      label={label}
      inputProps={{ onChange }}
      value={fields[name] ?? ""}
      disabled={disabled}
    />
  );
};
