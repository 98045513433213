

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'Europe/Berlin',
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
  timeZoneName: 'short'
};

export const getFormattedDate = (date: Date | string): string => {
  const newDate = typeof date === 'string' ? new Date(date) : date
  
  const yyyy = newDate.getFullYear();
  let mm: number | String = newDate.getMonth() + 1;
  let dd: number | String = newDate.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '/' + mm + '/' + yyyy;
};

export const getLocaleStringdDate = (date: Date | string): string => {
  const newDate = typeof date === 'string' ? new Date(date) : date
  return newDate.toLocaleString("en-US", options).replace('GMT+1', '(GMT+1)');
};
