import { Accordion, Box, Grid, styled } from "@mui/material";

export const DocumentAccordeon = styled(Accordion)`
  margin: 12px 0;
  padding: 20px 32px;
  background: #ffffff;
  border-radius: 14px !important;
  border: none !important;
  &:first-child {
    margin-top: 0;
  }
  &::before {
    content: "";
    display: none;
  }
`;

export const DateGrid = styled(Grid)`
  color: grey;
  opacity: 0.7;
`;

export const StatisticGrid = styled(Grid)`
  margin-left: 8px;
  padding-left: 20px;
  position: relative;
  &:not(:last-child) {
    /* background-color: red; */
    padding-bottom: 20px;

    border-left: 1px solid #dee0e4;
  }
`;

export const StatiticStatusIcon = styled(Box)`
  height: 24px;
  left: -11px;
  position: absolute;
  background-color: white;
  & svg {
    vertical-align: middle;
  }
`;
