import React, { isValidElement, useCallback, FC } from "react";
import { TableRow, TableRowProps } from "@mui/material";

import { DatagridCell } from "./DataGridCell";
import { TId, TRecord } from "../../models";

export const DatagridRow: FC<DatagridRowProps> = React.forwardRef(
  (props, ref) => {
    const {
      children,
      className,
      hasBulkActions,
      hover,
      id,
      record,
      onRowClick,
      selected,
      style,
      selectable,
      ...rest
    } = props;
    const handleClick = useCallback(() => {
      if (onRowClick && id && record) {
        onRowClick(id, record);
      }
    }, [id, record, onRowClick]);

    return (
      <TableRow
        ref={ref}
        key={id}
        style={style}
        hover={!selected && (hover || !!onRowClick)}
        sx={{
          background: record?.deletedAt || selected ? "#c4c2c2" : "#ffffff",
          cursor: onRowClick ? "pointer" : "default",
        }}
        onClick={onRowClick && handleClick}
        {...rest}
      >
        {React.Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridCell
              key={`${id}-${(field.props as any).source || index}`}
              record={record}
              {...{ field }}
            />
          ) : null
        )}
      </TableRow>
    );
  }
);

export interface DatagridRowProps
  extends Omit<TableRowProps, "id" | "classes"> {
  hasBulkActions?: boolean;
  hover?: boolean;
  id?: TId;
  record?: TRecord;
  resource?: string;
  onRowClick?: TRowClickFunction;
  selected?: boolean;
  style?: any;
  selectable?: boolean;
}

export type TRowClickFunction = (id: TId, record: TRecord) => void;
