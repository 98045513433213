import * as React from 'react';
import { Children, isValidElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';

import DatagridHeaderCell from './DataGridHeaderCell';

/**
 * The default Datagrid Header component.
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export const DatagridHeader = (props: DatagridHeaderProps) => {
  const {
    children,
    isRowSelectable,
    sort,
    data,
    onSelect,
    selectedIds,
    setSort,
  } = props;

  // const updateSortCallback = useCallback(
  //   (event) => {
  //     event.stopPropagation();
  //     const newField = event.currentTarget.dataset.field;
  //     const newOrder =
  //       sort.field === newField
  //         ? sort.order === 'ASC'
  //           ? 'DESC'
  //           : 'ASC'
  //         : event.currentTarget.dataset.order;

  //     setSort({ field: newField, order: newOrder });
  //   },
  //   [sort.field, sort.order, setSort]
  // );

  // const updateSort = setSort ? updateSortCallback : null;

  // const handleSelectAll = useCallback(
  //   (event) =>
  //     onSelect(
  //       event.target.checked
  //         ? selectedIds.concat(
  //             data
  //               .filter((record) => !selectedIds.includes(record.id))
  //               .filter((record) =>
  //                 isRowSelectable ? isRowSelectable(record) : true
  //               )
  //               .map((record) => record.id)
  //           )
  //         : []
  //     ),
  //   [data, onSelect, isRowSelectable, selectedIds]
  // );

  // const selectableIds = Array.isArray(data)
  //   ? isRowSelectable
  //     ? data
  //         .filter((record) => isRowSelectable(record))
  //         .map((record) => record.id)
  //     : data.map((record) => record.id)
  //   : [];


  return (
    <TableHead>
      <TableRow sx={{ background: '#ffffff' }}>
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridHeaderCell
              sort={sort}
              field={field}
              // isSorting={
              //   sort.field ===
              //   ((field.props as any).sortBy || (field.props as any).source)
              // }
              key={(field.props as any).source || index}
              // updateSort={updateSort}
            />
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

export type DatagridHeaderProps = any;
// export interface DatagridHeaderProps<RecordType extends RaRecord = any> {
//   children?: React.ReactNode;
//   className?: string;
//   hasExpand?: boolean;
//   hasBulkActions?: boolean;
//   isRowSelectable?: (record: RecordType) => boolean;
//   isRowExpandable?: (record: RecordType) => boolean;
//   size?: 'medium' | 'small';
//   // can be injected when using the component without context
//   sort?: SortPayload;
//   data?: RecordType[];
//   onSelect?: (ids: Identifier[]) => void;
//   onToggleItem?: (id: Identifier) => void;
//   resource?: string;
//   selectedIds?: Identifier[];
//   setSort?: (sort: SortPayload) => void;
// }

// DatagridHeader.displayName = 'DatagridHeader';
