import { useCallback, useEffect, useState } from 'react';
import { acceptVerificationRequest } from '../network';

export const useVerificationRequestAccept = (id?: string): [
  any | null,
  boolean,
  Error | null,
  (requestId?: string) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (requestId?: string) => {
    setIsLoading(true);
    setError(null);

    requestId = requestId || id;

    if (requestId) {
      try {
        const response = await acceptVerificationRequest(requestId);
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }
    } else {
      setError(new Error('ID is not provided'));
    }

    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      request();
    }
  }, [request, id]);

  return [result, isLoading, error, request];
};
