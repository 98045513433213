import * as React from 'react';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { TRecord, TSortData } from '../../models';

export const DatagridHeaderCell = (props: DatagridHeaderCellProps): JSX.Element => {
  const { field, sort, updateSort, isSorting, ...rest } = props;

  if (!field) {
      throw new Error('Field is undefined');
  }

  return (
    <TableCell
      align={field.props.textAlign}
      variant='head'
      {...rest}
    >
      {updateSort &&
      field.props.sortable &&
      (field.props.sortBy || field.props.source) ? (
        <Tooltip
          title='Sort'
          placement={
            field.props.textAlign === 'right' ? 'bottom-end' : 'bottom-start'
          }
          enterDelay={300}
        >
          <TableSortLabel
            active={sort?.field === (field.props.sortBy || field.props.source)}
            direction={sort?.order}
            data-field={field.props.sortBy || field.props.source}
            data-order={field.props.sortByOrder || 'ASC'}
            onClick={updateSort}
          >
            <span>{field.props.label || field.props.source}</span>
          </TableSortLabel>
        </Tooltip>
      ) : (
        <span>{field.props.label || field.props.source}</span>
      )}
    </TableCell>
  );
};

export interface DatagridHeaderCellProps {
  field?: JSX.Element;
  isSorting?: boolean;
  sort?: TSortData;
  updateSort?: (event: any) => void;
}

export default memo(
  DatagridHeaderCell,
  (props, nextProps) =>
    props.updateSort === nextProps.updateSort &&
    props.sort?.field === nextProps.sort?.field &&
    props.sort?.order === nextProps.sort?.order &&
    props.isSorting === nextProps.isSorting
);
