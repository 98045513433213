import { useCallback, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Box, FormControl, Modal } from "@mui/material";
import { MenuCompanies } from "../../modules/company/components/MenuCompanies";
import { ShevronIcon } from "../MuiIcons";
import { CompanyCard, ManageBtn, TitleCompany, Logo } from "./styles";
import {
  AUTH_COMPANY_ID,
  AUTH_COMPANY_NAME,
} from "../../modules/company/models";
import { useCompaniesList } from "../../modules/company/hooks/useCompaniesList";

export const AppCompanyMenu = () => {
  const navigate = useNavigate();

  const [data, loading] = useCompaniesList();
  const authorizedCompanyId = localStorage.getItem(AUTH_COMPANY_ID);
  const currentCompany = localStorage.getItem(AUTH_COMPANY_NAME);
  const [open, setOpen] = useState(false);

  const filterData = useMemo(
    () => data?.items.filter((e: any) => e.isActive),
    [data]
  );

  const { logoCompany } = useMemo(
    () => ({
      logoCompany:
        currentCompany &&
        currentCompany
          .split(" ")
          .map((el: string) => el !== "-" && el[0].toUpperCase())
          .join(""),
    }),
    [currentCompany]
  );

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleManageBtn = () => {
    navigate("/companies");
    handleClose();
  };

  if (authorizedCompanyId === null) {
    return null;
  }

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ width: "100%" }}
    >
      <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
        {logoCompany && (
          <Logo>
            <Typography variant='body1' color='#1C1F21' fontWeight='700'>
              {logoCompany}
            </Typography>
          </Logo>
        )}
        <Box>
          <>
            <FormControl
              variant='standard'
              sx={{ m: 1, minWidth: 120, position: "relative" }}
            >
              {currentCompany && (
                <TitleCompany
                  endIcon={<ShevronIcon />}
                  sx={{
                    "& .MuiButton-endIcon": {
                      marginRight: "4px",
                      position: "relative",
                      top: "4px",
                    },
                  }}
                  onClick={handleOpen}
                >
                  {currentCompany}
                </TitleCompany>
              )}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                sx={{
                  "& .MuiBackdrop-root": {
                    background: "none",
                  },
                }}
              >
                <CompanyCard>
                  {data && (
                    <MenuCompanies
                      data={{ items: filterData }}
                      isLoading={loading}
                      action={
                        <ManageBtn onClick={handleManageBtn}>
                          Manage companies
                        </ManageBtn>
                      }
                    />
                  )}
                </CompanyCard>
              </Modal>
            </FormControl>
          </>
        </Box>
      </Stack>
    </Stack>
  );
};
