import { styled, Stack, Typography } from '@mui/material'

export const DocumentImgWrapper = styled(Stack)`
  padding: 16px 24px 16px 16px;
  background: #f4f4f4;
  border-radius: 12px;
`

export const Label = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1c1f21;
`
