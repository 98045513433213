import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import {
  DateText,
  IconBox,
  PageWrapper,
} from "../../components/PageVerificaionLayout/styles";
import { getDocumentIcon } from "../../utils/getDocumentIcon";
import { titleCase } from "../../../../utils";

export const PageVerificationHeader: FC<any> = ({ data }) => {
  return (
    <PageWrapper>
      <Box mb='16px'>
        <DateText>
          {data?.createdAt && new Date(data?.createdAt).toDateString()}
        </DateText>
      </Box>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        {data?.documents?.map(
          ({
            document: { documentId, documentType },
          }: {
            document: { documentId: string; documentType: string };
          }) => (
            <Grid item xs={2} sm={4} md={4} key={documentId}>
              <Stack
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'
                gap='16px'
              >
                <IconBox>{getDocumentIcon(documentType)}</IconBox>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    color: "#1C1F21",
                  }}
                >
                  {titleCase(documentType)}
                </Typography>
              </Stack>
            </Grid>
          )
        )}
      </Grid>
    </PageWrapper>
  );
};
