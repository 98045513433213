import * as React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { sanitizeFieldRestProps } from './sanitizeFieldRestProps';
import { BaseViewFieldProps, InjectedViewFieldProps } from './types';
import { TRecord } from '../../models';

/**
 * Field using a render function
 *
 * @example
 * <FunctionField
 *     source="last_name" // used for sorting
 *     label="Name"
 *     render={record => record && `${record.first_name} ${record.last_name}`}
 * />
 */

export const FunctionViewField = <RecordType extends TRecord = any>(
    props: FunctionViewFieldProps<RecordType>
) => {
    const { className, record, source = '', render, ...rest } = props;
    return useMemo(
        () =>
            record ? (
                <Typography
                    component="span"
                    variant="body2"
                    className={className}
                    {...sanitizeFieldRestProps(rest)}
                >
                    {render(record, source)}
                </Typography>
            ) : null,
        [className, record, source, render, rest]
    );
};

export interface FunctionViewFieldProps<RecordType extends TRecord = any>
    extends BaseViewFieldProps,
        InjectedViewFieldProps<RecordType>,
        Omit<TypographyProps, 'textAlign'> {
    render: (record: RecordType, source?: string) => any;
}
