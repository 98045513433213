import { useCallback, useEffect, useState } from 'react';
import AwaitType from '../../../utils/AwaitType';
import { getAppList } from '../network';

export type TResult = null | AwaitType<ReturnType<typeof getAppList>>

export const useAppList = (): [
  any | null,
  boolean,
  Error | null,
  () => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getAppList();
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
