import { FC, useState } from "react";
import {
  Stack,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FunnelIcon, TrashIcon } from "../../../../components/MuiIcons";
import { ClearButton, FilterButton, FilterPopover, HeadButton } from "./styles";
import { TPagingResponse } from "../../../../models/PagingResponse";
import { TCompany } from "../../models";

export const ComplianceFilter: FC<{
  onChangeFilter: (value: string) => void;
  companies: TPagingResponse<TCompany>;
}> = ({ onChangeFilter, companies }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string>("");

  const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersClose = () => {
    setAnchorEl(null);
  };

  const onSaveFilters = () => {
    onChangeFilter(selectedFilters);
    handleFiltersClose();
  };

  const onClearFilters = () => {
    setSelectedFilters("");
    onChangeFilter("");
    handleFiltersClose();
  };

  const handleChangeFilter = (event: any) => {
    setSelectedFilters(event?.target?.name);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Stack direction='row' spacing={1}>
        <HeadButton
          aria-describedby={id}
          variant='text'
          onClick={handleFilterBtnClick}
          startIcon={<FunnelIcon />}
        >
          Filter
        </HeadButton>
      </Stack>
      <FilterPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFiltersClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography fontWeight='bold'>Company</Typography>
        <FormGroup sx={{ mt: 2, mb: 2 }}>
          {companies.items?.map((company) => (
            <FormControlLabel
              control={<Checkbox />}
              label={company.name}
              name={company.id}
              checked={selectedFilters === company.id}
              onChange={handleChangeFilter}
            />
          ))}
        </FormGroup>
        <Stack direction='row' spacing={2}>
          <ClearButton
            variant='contained'
            startIcon={<TrashIcon />}
            onClick={onClearFilters}
          >
            Clear filter
          </ClearButton>
          <FilterButton variant='contained' onClick={onSaveFilters}>
            Save
          </FilterButton>
        </Stack>
      </FilterPopover>
    </>
  );
};
