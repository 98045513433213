import { CopyToClipboard } from "react-copy-to-clipboard";
import { FC, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/Copy.svg";

type CustomCopyClipboardPlacement =
  | "top-end"
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-start"
  | undefined;

interface ICustomCopyClipboard {
  text: string;
  inside?: boolean;
  noMargin?: boolean;
  titleIsCopy?: string;
  titleBeforeCopy?: string;
  children?: any;
  placement?: CustomCopyClipboardPlacement;
  fullWidth?: boolean;
}

export const CustomCopyClipboard: FC<ICustomCopyClipboard> = ({
  text,
  inside = false,
  children,
  noMargin = false,
  placement = "top-end",
  titleIsCopy = "Copied",
  titleBeforeCopy = "Click to copy",
  fullWidth = false,
}) => {
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 1500);
    }
  }, [isCopy]);

  return (
    <Grid
      container
      alignItems='center'
      sx={[
        { marginLeft: !noMargin ? "16px" : 0 },
        inside
          ? {
              position: "absolute",
              height: "32px",
              width: "50px",
              right: "8px",
              top: "8px",
              "& path": {
                fill: "#9FA4AE",
              },
            }
          : {},
      ]}
      justifyContent={inside ? "flex-end" : "initial"}
      wrap='nowrap'
    >
      <CopyToClipboard
        text={`${text}`}
        onCopy={() => {
          setIsCopy(true);
        }}
      >
        <Tooltip
          arrow
          classes={{ arrow: "arrow", tooltip: "tooltip" }}
          placement={placement}
          style={fullWidth ? { width: "100%" } : {}}
          title={isCopy ? titleIsCopy : titleBeforeCopy}
        >
          {children ? (
            children
          ) : (
            <CopyIcon
              style={{
                height: 20,
                width: 20,
                cursor: "pointer",
              }}
              fill='#9FA4AE'
            />
          )}
        </Tooltip>
      </CopyToClipboard>
    </Grid>
  );
};
