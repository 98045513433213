import { memo, useCallback, useState } from "react";
import { Alert } from "@mui/material";
import { CreateCompanyDialog } from "../../components/CreateCompanyDialog";
import { CompanyPageLayout } from "../../components/CompanyPageLayout";
import { CompanySelectCard } from "../../components/CompanySelectCard";
import { useCompaniesList } from "../../hooks/useCompaniesList";

export const CompanySelectListScreen = memo(() => {
  const [data, loading, error, reload] = useCompaniesList();
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const handleOpenCreate = useCallback(() => {
    setIsCreateOpen(true);
  }, [setIsCreateOpen]);

  const handleCloseCreate = useCallback(
    (success: boolean) => {
      setIsCreateOpen(false);
      if (success) {
        reload();
      }
    },
    [setIsCreateOpen, reload]
  );

  return (
    <CompanyPageLayout
      loading={loading}
      empty={error === null && (data === null || data.items.length === 0)}
    >
      {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      <CompanySelectCard
        handleOpenCreate={handleOpenCreate}
        data={data}
        isLoading={loading}
      />
      <CreateCompanyDialog open={isCreateOpen} onClose={handleCloseCreate} />
    </CompanyPageLayout>
  );
});
