import { Box, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { TResult } from "../../hooks/useDocumentListWithData";
import { TDocument, TVerificationRequestStatistic } from "../../models";
import { ActionPanel } from "../ActionPanel";
import { DocumentInfoCard } from "../DocumentInfoCard";
import { DuplicatesPanel } from "../DuplicatesPanel";
import { LeftColumn, RightColumn } from "./styles";
import { DatabaseScreeningPanel } from "../DatabaseScreeningPanel";
import { StatisticPanel } from "../StatisticPanel";
import { useWidth } from "../../../app/hooks/useWidth";

export const PageVerificationContent: FC<{
  data: any;
  documents: TResult;
  statistic: TVerificationRequestStatistic[] | null;
  onAction: (value: string) => void;
  id?: string;
  loading: boolean;
  request: any;
}> = ({ data, documents, statistic, onAction, id, request, loading }) => {
  const width = useWidth();
  const verificationNew = useMemo(() => {
    if (!documents?.length) {
      return {
        data: [
          {
            dataBase64: "",
            dataId: "UserInformation",
            dataLink: "",
            document: "UserInformation",
            id: "newVerify",
            type: "binary",
            verificationDocument: "UserInformation",
            data: { email: data?.email },
          },
        ],
        document: {
          documentId: "UserInformation",
          documentType: "UserInformation",
        },
      } as TDocument;
    }
  }, [data, documents?.length]);
  return (
    <>
      {["xs"].includes(width) ? (
        <RightColumn>
          <ActionPanel
            loading={loading}
            status={data?.status}
            onAction={onAction}
          />
          <DatabaseScreeningPanel data={data} />
          <DuplicatesPanel />
        </RightColumn>
      ) : null}
      <Box sx={{ margin: "32px 0 24px" }}>
        <Typography
          variant='h5'
          sx={{
            fontWeight: "800",
            letterSpacing: "0.5px",
            color: "#1C1F21",
          }}
        >
          Applicant data
        </Typography>
      </Box>
      <Box width='100%' paddingBottom='40px'>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
        >
          <LeftColumn
            sx={{
              flexGrow: 2,
            }}
          >
            <StatisticPanel data={data} statistic={statistic} />
            {documents?.length ? (
              documents?.map((item: TDocument) => (
                <DocumentInfoCard
                  request={request}
                  key={item.document.documentId}
                  requestId={id!}
                  item={item}
                />
              ))
            ) : (
              <DocumentInfoCard
                request={request}
                key={"newVerification"}
                requestId={id!}
                item={verificationNew!}
              />
            )}
          </LeftColumn>
          {!["xs"].includes(width) ? (
            <RightColumn>
              <ActionPanel
                loading={loading}
                status={data?.status}
                onAction={onAction}
              />
              <DatabaseScreeningPanel data={data} />
              <DuplicatesPanel />
            </RightColumn>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};
