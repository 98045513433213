import { useCallback, useEffect, useState } from 'react';
import AwaitType from '../../../utils/AwaitType';
import { getVerificationRequestListForCompany } from '../network';

export type TResult = null | AwaitType<
  ReturnType<typeof getVerificationRequestListForCompany>
>;

export const useDocumentsList = (
  offset: number = 0,
  limit: number = 10,
  companyId: string | null,
  statuses: string[] = [],
  query: string = ''
): [
  any | null,
  boolean,
  Error | null,
  (offset?: number, statuses?: string[], query?: string) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (offset = 0, statuses = [], query = '') => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await getVerificationRequestListForCompany(
          limit,
          offset,
          companyId,
          statuses,
          query
        );
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }
      setIsLoading(false);
    },
    [limit, companyId]
  );

  // useEffect(() => {
  //   request(offset, statuses, query);
  // }, [request, offset, statuses, query]);

  return [result, isLoading, error, request];
};
