import { useMemo } from "react";
import { FilledTextArea } from "../../../../components/FilledTextArea";
import countries from "../../data/countries.json";

export const SecondNationality = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
}) => {
  const { onChange, fields, name, label, disabled } = props;

  const country = useMemo(() => {
    return countries.find((country) => country.code === fields[name])?.name;
  }, [fields, name]);

  return (
    <FilledTextArea
      name={name}
      label={country || !fields[name] ? "Second Nationality" : label}
      inputProps={{ onChange }}
      value={country ?? fields[name] ?? ""}
      disabled={disabled}
    />
  );
};
