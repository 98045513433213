import { useCallback, useState } from "react";
import { uploadDocumentData } from "../network";

export const useUploadDocumentData = (
  id: string,
  documentType: string,
  data: any
): [any | null, boolean, Error | null, (documentId?: string) => void] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (documentId?: string) => {
      setIsLoading(true);
      setError(null);

      documentId = documentId || id;

      if (documentId) {
        try {
          const response = await uploadDocumentData(
            documentId,
            documentType,
            data
          );
          setResult(response);
        } catch (e) {
          if (e instanceof Error) {
            setError(e);
          } else {
            setError(new Error("Unknown error"));
          }
        }
      } else {
        setError(new Error("ID is not provided"));
      }

      setIsLoading(false);
    },
    [id, documentType, data]
  );

  return [result, isLoading, error, request];
};
