import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { generateKeys, getApplicationsList } from "../../network";
import { fromBase64 } from "../../../../utils";
import { IGeneratedKeys, IViewKeys, TCompany } from "../../models";
import { TApp } from "../../../app/models";
import { FunctionViewField, ListContainer, DataGrid, TId } from "../../../ui";
import { getAppKeys, updateSdkSettings } from "../../../app/network";
import { CustomCopyClipboard } from "../../../ui/components/CustomCopyToClipboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DocumentAccordeon } from "../../../verificationRequest/components/DocumentInfoCard/styles";

type Props = {
  onClose: (success: boolean) => void;
  setAppList: React.Dispatch<React.SetStateAction<[] | TApp[]>>;
  open: boolean;
  appList: TApp[] | [];
  selectedCompany: TCompany | null;
};

export const IntegrationConfigDialog = (props: Props) => {
  const { onClose, open, appList, selectedCompany, setAppList } = props;
  const [generateMode, setGenerateMode] = useState(false);
  const [webHookUrl, setWebHookUrl] = useState("");
  const [savedWebHookUrl, setSavedWebHookUrl] = useState("");
  const [applicationName, setApplicationName] = useState("KYC");
  const [generatedKeys, setGeneratedKeys] = useState<IGeneratedKeys | null>(
    null
  );
  const [generatedLoading, setGeneratedLoading] = useState(false);
  const [viewKeys, setViewKeys] = useState<IViewKeys | null>(null);
  const [selectedAppId, setsSelectedAppId] = useState<TId | null>(null);

  const [errors, setErrors] = useState<any>({});

  const goGenerateMode = () => {
    setWebHookUrl("");
    setSavedWebHookUrl("");
    setApplicationName("KYC_" + selectedCompany?.name);
    setGenerateMode(true);
    setsSelectedAppId(null);
    setViewKeys(null);
  };
  const goViewMode = async (e: TId) => {
    setsSelectedAppId(e);
    try {
      const keys = await getAppKeys(e);
      const decodedWebhookUrl = keys.webhookUrl
        ? fromBase64(keys.webhookUrl)
        : keys.webhookUrl;
      setSavedWebHookUrl(decodedWebhookUrl);
      setWebHookUrl(decodedWebhookUrl);
      setViewKeys(keys);
      setGenerateMode(false);
    } catch (e) {
      setErrors({ network: e });
    }
  };

  useEffect(() => {
    if (appList.length === 1) {
      goViewMode(appList[0].id);
    }
  }, [appList]);

  const handleCancel = useCallback(() => {
    setWebHookUrl("");
    setSavedWebHookUrl("");
    setApplicationName("");
    setGeneratedKeys(null);
    setViewKeys(null);
    setsSelectedAppId(null);
    setGenerateMode(false);
    onClose(false);
    setErrors({});
  }, [onClose]);

  const keysToView = useMemo(() => {
    return viewKeys || generatedKeys;
  }, [viewKeys, generatedKeys]);

  const handleGenerateKeys = async (
    webHookUrl: string,
    applicationName: string
  ) => {
    setGeneratedLoading(true);
    try {
      const keys = await generateKeys({
        webHookUrl,
        applicationName,
      });
      if (webHookUrl) {
        setSavedWebHookUrl(webHookUrl);
      }
      setGeneratedKeys(keys);
      const apps = await getApplicationsList();
      setAppList(apps);
    } catch (e) {
      setErrors({ network: e });
    } finally {
      setGeneratedLoading(false);
    }
  };

  const changeWebHookUrl = () => {
    if (!webHookUrl) {
      setErrors({ webHookUrl: true });
    } else {
      try {
        updateSdkSettings(fromBase64(keysToView!.appToken), {
          webhookUrl: webHookUrl,
        });
      } catch (e) {
        setErrors({ network: e });
      }
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      fullWidth
      id='interation-config-dialog'
      keepMounted
    >
      <DialogTitle sx={{ textTransform: "capitalize" }}>
        Integration configuration
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#e5e5e5" }} dividers>
        {errors.network && (
          <Alert sx={{ marginBottom: "8px" }} severity='error'>
            {errors.network.toString()}
            <Button onClick={() => setErrors({})}>hide</Button>
          </Alert>
        )}
        <Stack>
          <Typography variant='h6'>Applications</Typography>
          <ListContainer data={appList}>
            <DataGrid selectedId={selectedAppId} onRowClick={goViewMode}>
              <FunctionViewField
                label='Name'
                source='name'
                render={(record: TApp) => (
                  <Box>
                    <Typography variant='h6'>{record.name}</Typography>
                  </Box>
                )}
              />
              <FunctionViewField
                label='ID'
                source='id'
                render={(record: TApp) => (
                  <Box>
                    <Typography variant='body2'>{record.id}</Typography>
                  </Box>
                )}
              />
            </DataGrid>
          </ListContainer>
          {!generateMode ? (
            <Stack alignItems='flex-end'>
              <Button sx={{ width: 200 }} onClick={goGenerateMode}>
                Add new application
              </Button>
            </Stack>
          ) : null}
        </Stack>

        <Grid container direction='column'>
          {generateMode ? (
            <TextField
              label='Application name'
              name='appName'
              required={true}
              size='small'
              margin='normal'
              value={applicationName}
              inputProps={{
                onChange: (e: any) => {
                  return setApplicationName(e.target.value);
                },
              }}
            />
          ) : null}
          {generateMode ? (
            <Button
              onClick={() => handleGenerateKeys(webHookUrl, applicationName)}
              disabled={
                !applicationName || Boolean(generatedKeys) || generatedLoading
              }
            >
              Generate Keys
            </Button>
          ) : null}
          {keysToView ? (
            <>
              <DocumentAccordeon defaultExpanded>
                <AccordionSummary
                  sx={{ "& .MuiAccordionSummary-root": { border: "none" } }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography variant='h6'>
                    Website integration with a link
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={`${
                      keysToView.widgetUrl ?? ""
                    }/?appPublicKey=${fromBase64(keysToView.appPublicKey)}`}
                  >
                    <TextField
                      label='Widget URL'
                      name='widgetUrl'
                      size='small'
                      margin='normal'
                      value={`${
                        keysToView.widgetUrl ?? ""
                      }/?appPublicKey=${fromBase64(keysToView.appPublicKey)}`}
                      inputProps={{
                        "aria-disabled": "true",
                      }}
                    />
                  </CustomCopyClipboard>
                </AccordionDetails>
              </DocumentAccordeon>
              <DocumentAccordeon>
                <AccordionSummary
                  sx={{ "& .MuiAccordionSummary-root": { border: "none" } }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography variant='h6'>
                    SDK integration with webhooks to your clients database
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!savedWebHookUrl ? (
                    <Stack sx={{ marginTop: "16px" }}>
                      <Alert color='warning' severity='info'>
                        <AlertTitle>Important</AlertTitle>
                        Specify the target url where we should send webhooks to
                        notify you about the changes in statuses for applicants
                      </Alert>
                    </Stack>
                  ) : null}
                  <TextField
                    fullWidth
                    error={errors.webHookUrl}
                    label='Url for webhooks'
                    name='webhookUrl'
                    size='small'
                    margin='normal'
                    required
                    value={webHookUrl}
                    inputProps={{
                      onChange: (e: any) => {
                        delete errors.webHookUrl;
                        return setWebHookUrl(e.target.value);
                      },
                    }}
                  />
                  <Stack alignItems='flex-end'>
                    <Button
                      sx={{ width: 200 }}
                      onClick={changeWebHookUrl}
                      disabled={
                        Boolean(savedWebHookUrl) &&
                        savedWebHookUrl === webHookUrl
                      }
                    >
                      Save webhook URL
                    </Button>
                  </Stack>
                  {generateMode ? (
                    <Stack sx={{ marginTop: "16px" }}>
                      <Alert color='warning' severity='info'>
                        <AlertTitle>Important</AlertTitle>
                        Save your Private Key in a safe place. For security
                        reasons, you won’t be able to view it again once you
                        close this dialog.
                      </Alert>
                    </Stack>
                  ) : null}
                  {generatedKeys ? (
                    <CustomCopyClipboard
                      fullWidth
                      noMargin
                      titleBeforeCopy=''
                      placement='top'
                      text={fromBase64(generatedKeys.privateKey)}
                    >
                      <TextField
                        label='Private Key'
                        name='private_key'
                        size='small'
                        margin='normal'
                        value={fromBase64(generatedKeys.privateKey)}
                        inputProps={{ "aria-disabled": "true" }}
                        multiline
                      />
                    </CustomCopyClipboard>
                  ) : null}
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={fromBase64(keysToView.publicKey ?? "")}
                  >
                    <TextField
                      label='Public Key'
                      name='public_key'
                      size='small'
                      margin='normal'
                      value={fromBase64(keysToView.publicKey ?? "")}
                      inputProps={{ "aria-disabled": "true" }}
                      multiline
                    />
                  </CustomCopyClipboard>
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={keysToView.apiOrigin}
                  >
                    <TextField
                      label='Api Origin'
                      name='api_origin'
                      size='small'
                      margin='normal'
                      value={keysToView.apiOrigin}
                      inputProps={{ "aria-disabled": "true" }}
                      multiline
                    />
                  </CustomCopyClipboard>
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={fromBase64(keysToView.appPublicKey)}
                  >
                    <TextField
                      label='App Public Key'
                      name='app_public_key'
                      size='small'
                      margin='normal'
                      value={fromBase64(keysToView.appPublicKey)}
                      inputProps={{ "aria-disabled": "true" }}
                      multiline
                    />
                  </CustomCopyClipboard>
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={fromBase64(keysToView.appToken)}
                  >
                    <TextField
                      label='App Token'
                      name='app_token'
                      size='small'
                      margin='normal'
                      value={fromBase64(keysToView.appToken)}
                      inputProps={{ "aria-disabled": "true" }}
                    />
                  </CustomCopyClipboard>
                  <CustomCopyClipboard
                    fullWidth
                    noMargin
                    titleBeforeCopy=''
                    placement='top'
                    text={fromBase64(keysToView.webhookSecretKey)}
                  >
                    <TextField
                      label='Webhook Secret Key'
                      name='webhook_secret_key'
                      size='small'
                      margin='normal'
                      value={fromBase64(keysToView.webhookSecretKey)}
                      inputProps={{ "aria-disabled": "true" }}
                    />
                  </CustomCopyClipboard>
                </AccordionDetails>
              </DocumentAccordeon>
            </>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
