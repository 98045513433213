import { FC, useState } from "react";
import {
  Stack,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FunnelIcon, ReloadIcon, TrashIcon } from "../MuiIcons";
import { ClearButton, FilterButton, FilterPopover, HeadButton } from "./styles";

export const HeadButtons: FC<{
  onChangeFilter: (value: string[]) => void;
  onReload: () => void;
}> = ({ onChangeFilter, onReload }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState({
    ACCEPTED: false,
    CANCELED: false,
    NEW: false,
    SENT_TO_VERIFICATION: false,
    INFORMATION_REQUESTED: false,
  });

  const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersClose = () => {
    setAnchorEl(null);
  };

  const onSaveFilters = () => {
    const newFilters = Object.entries(selectedFilters)
      .filter((elem) => elem[1])
      .map((elem) => elem[0]);
    onChangeFilter(newFilters);
    handleFiltersClose();
  };

  const onClearFilters = () => {
    setSelectedFilters({
      ACCEPTED: false,
      CANCELED: false,
      NEW: false,
      SENT_TO_VERIFICATION: false,
      INFORMATION_REQUESTED: false,
    });
    onChangeFilter([]);
    handleFiltersClose();
  };

  const handleChangeFilter = (event: any) => {
    setSelectedFilters({
      ...selectedFilters,
      [event?.target?.name]: event?.target?.checked,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Stack direction='row' spacing={1}>
        <HeadButton
          aria-describedby={id}
          variant='text'
          onClick={handleFilterBtnClick}
          startIcon={<FunnelIcon />}
        >
          Filter
        </HeadButton>
        <HeadButton
          variant='text'
          startIcon={<ReloadIcon />}
          onClick={onReload}
        >
          Refresh
        </HeadButton>
      </Stack>
      <FilterPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFiltersClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography fontWeight='bold'>Status</Typography>
        <FormGroup sx={{ mt: 2, mb: 2 }}>
          <FormControlLabel
            control={<Checkbox />}
            label='Accepted'
            name={"ACCEPTED"}
            checked={selectedFilters.ACCEPTED}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Rejected'
            name={"CANCELED"}
            checked={selectedFilters.CANCELED}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='New'
            name={"NEW"}
            checked={selectedFilters.NEW}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Sent to verification'
            name={"SENT_TO_VERIFICATION"}
            checked={selectedFilters.SENT_TO_VERIFICATION}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Information requested'
            name={"INFORMATION_REQUESTED"}
            checked={selectedFilters.INFORMATION_REQUESTED}
            onChange={handleChangeFilter}
          />
        </FormGroup>
        <Stack direction='row' spacing={2}>
          <ClearButton
            variant='contained'
            startIcon={<TrashIcon />}
            onClick={onClearFilters}
          >
            Clear filter
          </ClearButton>
          <FilterButton variant='contained' onClick={onSaveFilters}>
            Save
          </FilterButton>
        </Stack>
      </FilterPopover>
    </>
  );
};
