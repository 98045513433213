import { styled, Tab } from '@mui/material'

export const TabList = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  background: 'none',
  borderRadius: 'none',
  border: 'none',
  textTransform: 'capitalize',
  fontFamily: 'Red Hat Display',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#8D959F',
  ...(active && {
    background: '#ffffff',
    borderRadius: '10px',
  }),
  '&:hover': {
    color: '#40a9ff',
  },
  '&.Mui-selected': {
    color: 'black',
  },
}))
