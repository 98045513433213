import { FC, memo } from 'react'
import { Box, Stack, Typography } from '@mui/material'

export const CompanyPageLayout: FC<{
  children: React.ReactNode
  empty: boolean
  loading: boolean
}> = memo(({ children, empty, loading }) => (
  <Box sx={{ width: '100%', height: '100vh', minHeight: '614px' }}>
    <Stack
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      paddingTop={{ sm: '124px', xs: '64px' }}
    >
      {children}
      {empty && !loading && (
        <Typography variant='body2' color='textSecondary'>
          There is no data
        </Typography>
      )}
    </Stack>
  </Box>
))
