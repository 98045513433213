import { FC } from 'react'
import { SideBar } from '../SideBar'
import { AppLayoutWrapper } from './styles'

export type TAppLayoutProps = {
  children: React.ReactNode
}

export const AppLayout: FC<TAppLayoutProps> = ({ children }) => {
  return (
    <AppLayoutWrapper>
      <SideBar />
      {children}
    </AppLayoutWrapper>
  )
}
