import { FC } from "react";
import { SideBarWrapper } from "./styles";
import { SideBarIcons } from "./components/SideBarIcons";

export const SideBar: FC = () => {
  return (
    <SideBarWrapper
      direction='column'
      justifyContent='space-between'
      alignItems='center'
    >
      <SideBarIcons />
    </SideBarWrapper>
  );
};
