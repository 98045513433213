import { ApprovedIcon, PendingIcon } from "../../components/MuiIcons";
import { TCompany } from "./models";

export const statusIcons: {
  [key: string]: any;
} = {
  false: PendingIcon,
  true: ApprovedIcon,
};

export const statusesModel: {
  [key: string]: { bgColor: string; name: string; textColor: string };
} = {
  false: {
    bgColor: "#FFD70333",
    name: "Inactive",
    textColor: "#D2B102",
  },
  true: { bgColor: "#2CC3691A", name: "Active", textColor: "#2CC369" },
};

export const getStatusIcon = (
  documentStasus: boolean,
  props: any = {}
): JSX.Element => {
  const Icon = statusIcons[String(documentStasus)];
  return <Icon {...props} />;
};

export const getCompaniesNameFromIds = (
  companies: TCompany[],
  ids: string[]
) => {
  return ids
    .map((id) => companies.find((company) => company.id === id)?.name)
    .join(", ");
};

export const getCompaniesFromIds = (companies: TCompany[], ids: string[]) => {
  const companiesToReturn = [] as unknown as TCompany[];
  ids.forEach((id) => {
    const company = companies.find((company) => company.id === id);
    if (company) {
      companiesToReturn.push(company);
    }
  });
  return companiesToReturn;
};
