import { styled } from "@mui/material/styles";
import { Box, Button, AppBar } from "@mui/material";

export const TitleCompany = styled(Button)`
  position: relative;
  font-family: "Red Hat Display";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1c1f21;
  text-transform: capitalize;
`;
export const CompanyCard = styled(Box)`
  position: absolute;
  top: 49px;
  left: 54px;
  padding: 0 16px;
  width: 377px;
  max-height: 422px;
  background: #ffffff;
  box-shadow: 0px 9px 54px rgba(0, 0, 0, 0.07),
    0px 2.01px 12.0616px rgba(0, 0, 0, 0.0234994),
    0px 0.6px 3.59106px rgba(0, 0, 0, 0.017426);
  border-radius: 16px;
`;

export const ManageBtn = styled(Button)`
  position: relative;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2968ef;
`;

export const HeaderWrapper = styled(AppBar)`
  justify-content: center;
  height: 64px;
  background: #ffffff;
  box-shadow: none;
  border-bottom: 1px solid rgba(28, 31, 33, 0.1);
  @media screen and (max-width: 850px) {
    margin-bottom: -70px;
  }
`;

export const Logo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  border-radius: 10px;
`;
