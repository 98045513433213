import { FC, memo } from "react";
import { Box } from "@mui/material";
import { MenuCompanies } from "../MenuCompanies";
import { AddNewCompany, CardLayout, TitleCard } from "./styles";
import { AddIcon } from "../../../../components/MuiIcons";

export const CompanySelectCard: FC<{
  handleOpenCreate: () => void;
  data: any;
  isLoading: boolean;
}> = memo(({ handleOpenCreate, data, isLoading }) => (
  <CardLayout>
    <Box>
      <TitleCard>Select company</TitleCard>
    </Box>
    <MenuCompanies
      data={data}
      isLoading={isLoading}
      action={
        <AddNewCompany
          onClick={handleOpenCreate}
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: "4px",
              position: "relative",
              top: "2px",
            },
          }}
          variant='text'
          startIcon={<AddIcon />}
        >
          New company
        </AddNewCompany>
      }
    />
  </CardLayout>
));
