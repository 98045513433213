import { styled } from '@mui/material';

export const PdfContainer = styled('div')`
  height: 100%;
  & .react-pdf__Page {
    min-width: unset !important;
  }
  & canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
