import { useCallback, useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, Stack, Typography } from '@mui/material';
import {
  DialogCustomButton,
  DialogCustomContent,
  DialogCustomTitle,
  StyledTextarea,
} from './styles';
import { useVerificationRequestInformation } from '../../hooks/useVerificationRequestInformation';
import { WIDGET_URL } from '../../../../constants';

const getTextMessage = (data: any) => {
  return `Dear ${data?.additionalItems?.userInformationParsed?.first_name},


Please follow the link below to change your data:
${WIDGET_URL}/?appPublicKey=${data?.appPublicKey}&verificationRequestId=${data?.id}

As you click on the link, please login using your phone number and then choose the relevant section to update your profile.

If you have any issues during the process please contact compliance@oneboard.li

Best regards,
Oneboard team`;
};

export type Props = {
  onClose: (value: string) => void;
  open: boolean;
  documentId: string;
  data: any;
};

export const DocumentRequestInfoDialog = (props: Props) => {
  const { documentId, onClose, open, data } = props;
  const [message, setMessage] = useState('');
  const [result, loading, error, request] = useVerificationRequestInformation();

  useEffect(() => {
    if (result) {
      onClose('requestInfo');
    }
  }, [onClose, result]);

  const handleCancel = useCallback(() => {
    onClose('cancel');
  }, [onClose]);

  const handleOk = useCallback(() => {
    const messageReplaced = message.replace(/\n/g, '<br />');
    request(documentId, messageReplaced);
  }, [request, documentId, message]);

  useEffect(() => {
    setMessage(getTextMessage(data));
  }, [data]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '450px',
          borderRadius: '16px',
        },
      }}
      maxWidth='xs'
      open={open}
      id='document-review-dialog'
      keepMounted
    >
      <DialogCustomTitle>Message to client</DialogCustomTitle>
      <DialogCustomContent>
        Please specify here what the client needs to correct or add
        <StyledTextarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          aria-label='minimum height'
          minRows={3}
          placeholder='Type something'
        ></StyledTextarea>
        <Typography sx={{ fontSize: '0.8em', textAlign: 'justify', mt: 2 }}>
          If Selfie or Passport sections are not filled or missing - please
          instruct the client:
          <br />
          "Please click "Edit" button in "Identification" section. You will be
          asked to present your passport in front of the camera of your device
          and take a selfie. When finished, please click on "Send" to transmit
          the data to our compliance team"
        </Typography>
        {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      </DialogCustomContent>
      <DialogActions sx={{ padding: '0 32px 32px' }}>
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Box>
            <DialogCustomButton
              autoFocus
              onClick={handleCancel}
              disabled={loading}
              variant='outlined'
            >
              Cancel
            </DialogCustomButton>
          </Box>
          <Box>
            <DialogCustomButton
              onClick={handleOk}
              disabled={loading}
              variant='contained'
              color='primary'
            >
              Send
            </DialogCustomButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
