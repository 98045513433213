import { useCallback, useState } from 'react';
import AwaitType from '../../../utils/AwaitType';
import { TAppSettings } from '../models';
import { updateAppSettings } from '../network';

export type TResult = null | AwaitType<ReturnType<typeof updateAppSettings>>

export const useAppSettingsUpdate = (): [
  TResult,
  boolean,
  Error | null,
  (appId: string, data: TAppSettings) => void,
] => {
  const [result, setResult] = useState<TResult>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (appId: string, data: TAppSettings) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await updateAppSettings(appId, data);
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, []);

  return [result, isLoading, error, request];
};
