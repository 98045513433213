import { useCallback, useContext, useState } from 'react';
import { AuthContext, AuthContextValue } from './AuthProvider';

type Result = { salt: string };

export const useRequestSms = (): [
  Result | null,
  boolean,
  Error | null,
  (phone: string) => void
] => {
  const { requestSms } = useContext<AuthContextValue>(AuthContext);
  const [result, setResult] = useState<Result | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (phone: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestSms(phone);
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [requestSms]);

  return [result, isLoading, error, request];
};
