import { SvgIconProps } from "@mui/material";
import {
  ApprovedIcon,
  FolderIcon,
  CancelIcon,
  PendingIcon,
} from "../../../components/MuiIcons";
import DeleteIcon from "@mui/icons-material/DeleteForever";

export const statusIcons: {
  [key: string]: any;
} = {
  NEW: (props: SvgIconProps) => <PendingIcon {...props} fill='#2968EF' />,
  SENT_TO_VERIFICATION: PendingIcon,
  ACCEPTED: ApprovedIcon,
  CANCELED: CancelIcon,
  DELETED: (props: SvgIconProps) => (
    <DeleteIcon sx={{ height: 20, color: "#CF0000" }} {...props} />
  ),
  INFORMATION_REQUESTED: FolderIcon,
};

export const statusesModel: {
  [key: string]: { bgColor: string; name: string; textColor: string };
} = {
  NEW: { bgColor: "#D7E7FF", name: "New", textColor: "#2968EF" },
  SENT_TO_VERIFICATION: {
    bgColor: "#FFD70333",
    name: "Sent to verification",
    textColor: "#D2B102",
  },
  ACCEPTED: { bgColor: "#2CC3691A", name: "Accepted", textColor: "#2CC369" },
  CANCELED: { bgColor: "#CF00001A", name: "Rejected", textColor: "#CF0000" },
  DELETED: { bgColor: "#CF00001A", name: "Deleted", textColor: "#CF0000" },
  INFORMATION_REQUESTED: {
    bgColor: "#F4F4F4",
    name: "Information requested",
    textColor: "#8D959F",
  },
};

export const getStatusIcon = (
  documentStasus: string,
  props: any = {}
): JSX.Element => {
  if (documentStasus in statusIcons) {
    const Icon = statusIcons[documentStasus];
    return <Icon {...props} />;
  }

  return <></>;
};
