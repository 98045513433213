import { Box, styled, Typography } from '@mui/material'

export const StateBlock = styled(Box)`
  padding: 5px 8px;
  background: #d7e7ff;
  border-radius: 6px;
`

export const StateLabel = styled(Typography)`
  font-weight: 700;
  color: #2968ef;
`

export const StatusBlock = styled(Box)`
  padding: 5px 10px;
  background: #d7e7ff;
  border-radius: 6px;
`

export const StatusLabel = styled(Typography)`
  padding-left: 25px;
  font-weight: 700;
  color: #d2b102;
`
