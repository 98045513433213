import { InputBase, styled } from "@mui/material";

export const InputStyled = styled(InputBase)`
  max-width: 248px;
  min-width: 120px;
  background-color: white;
  border-radius: 8px;
  padding: 8px, 12px, 8px, 12px;
  border: 2px solid #dee0e4;
  & .MuiInputAdornment-root {
    width: 20px;
    margin-left: 8px;
    & > svg {
      width: 100%;
    }
  }
`;
