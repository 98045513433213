import { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../auth/useAuth";
import { authToCompany } from "../network";
import {
  AUTH_COMPANY_ID,
  AUTH_COMPANY_NAME,
  AUTH_COMPANY_PURPOSE,
  TCompany,
} from "../models";

export const useCompanyAuth = (
  commonCompanyId?: string
): [
  boolean | null,
  boolean,
  Error | null,
  (companyId?: string) => void,
  boolean, // Authorized
  (item: TCompany, companyId?: string) => void
] => {
  const { authorizedCompanyId, setTokenData } = useAuth();
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (companyId?: string) => {
      companyId = companyId || commonCompanyId;

      if (!companyId) {
        setError(new Error("Company Id not provided"));
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        setResult(null);
        const response = await authToCompany(companyId);
        setTokenData({
          ...response,
          companyId,
        });
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    [setTokenData, commonCompanyId]
  );

  const authHandler = (item: TCompany, companyId?: string) => {
    const { id, name, purpose } = item;
    localStorage.setItem(AUTH_COMPANY_ID, id);
    localStorage.setItem(AUTH_COMPANY_NAME, name);
    localStorage.setItem(AUTH_COMPANY_PURPOSE, purpose);
    request(companyId);
  };

  const isAuthorized: boolean = useMemo(() => {
    if (commonCompanyId && commonCompanyId === authorizedCompanyId) {
      return true;
    }
    return false;
  }, [commonCompanyId, authorizedCompanyId]);

  return [result, isLoading, error, request, isAuthorized, authHandler];
};
