import { ComponentProps, memo } from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export const CreateButton = memo((props: ComponentProps<typeof Button>) => {
  const { children, ...rest } = props
  return (
    <Button
      variant='contained'
      startIcon={<AddIcon />}
      {...rest}
      sx={{ backgroundColor: '#2968EF' }}
    >
      {children || 'Create'}
    </Button>
  )
})
