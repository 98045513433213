import React, { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PdfContainer } from './styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFViewer: FC<{ path?: string }> = ({ path }) => {
  const [numPages, setNumPage] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPage(numPages);
  };

  return (
    <PdfContainer>
      <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page
              pageNumber={page}
              key={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
      </Document>
    </PdfContainer>
  );
};

export default PDFViewer;
