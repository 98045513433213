import { Table } from "@mui/material";
import {
  isValidElement,
  ReactNode,
  cloneElement,
  createElement,
  ReactElement,
  ComponentType,
} from "react";
import { useListContext } from "../../containers";
import { DatagridHeader } from "./DataGridHeader";
import { DataGridBody } from "./DataGridBody";
import { TId } from "../../models";
import { TRowClickFunction } from "./DataGridRow";

export type TDataGridRow = {
  id: string | number;
  [key: string]: any;
};

export type TDataGridProps = {
  children: ReactNode;
  header?: ReactElement | ComponentType;
  body?: ReactElement | ComponentType;
  onRowClick?: TRowClickFunction;
  selectedId?: TId | null;
};

const createOrCloneElement = (element: any, props: any, children: any) =>
  isValidElement(element)
    ? cloneElement(element, props, children)
    : createElement(element, props, children);

export const DataGrid = (props: TDataGridProps) => {
  const {
    children,
    onRowClick,
    header = DatagridHeader,
    body = DataGridBody,
    selectedId = null,
  } = props;

  const { sort, data, selectedIds, setSort } = useListContext();

  return (
    <Table
      sx={{
        minWidth: 650,
        borderCollapse: "separate",
        borderSpacing: "0 16px ",
        "& tr th:first-child": {
          borderRadius: "16px 0 0 16px",
        },
        "& tr th:last-child": {
          borderRadius: "0 16px 16px 0",
        },
        "& .MuiTableCell-root": {
          padding: "12px",
        },
      }}
      aria-labelledby='tableTitle'
      size='medium'
      component='table'
    >
      {createOrCloneElement(
        header,
        {
          children,
          sort,
          data,
          selectedIds,
          setSort,
        },
        children
      )}
      {createOrCloneElement(
        body,
        {
          selectedId,
          data,
          onRowClick,
          selectedIds,
        },
        children
      )}
    </Table>
  );
};
