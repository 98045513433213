import { useCallback, useState } from "react";
import AwaitType from "../../../utils/AwaitType";
import { createCompany, TCreateCompanyData, updateCompany } from "../network";
import { ICompanyActionModeType } from "../models";

export type TResult = null | AwaitType<ReturnType<typeof createCompany>>;

export const useCompanyAction = (): [
  TResult,
  boolean,
  Error | null,
  (data: TCreateCompanyData, mode: ICompanyActionModeType) => void
] => {
  const [result, setResult] = useState<TResult>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: TCreateCompanyData, mode: ICompanyActionModeType) => {
      setIsLoading(true);
      setError(null);

      try {
        const response =
          mode === "create"
            ? await createCompany(data)
            : await updateCompany(data);
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
