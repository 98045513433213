import { useCallback, useContext, useState } from 'react';
import { AuthContext, AuthContextValue } from './AuthProvider';

export const useLogout = (): [
  boolean | null,
  boolean,
  Error | null,
  () => void
] => {
  const { logout } = useContext<AuthContextValue>(AuthContext);
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await logout();
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [logout]);

  return [result, isLoading, error, request];
};
