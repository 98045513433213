import { styled, Box, Typography, Button } from '@mui/material'

export const CardLayout = styled(Box)`
  padding: 32px;
  width: 409px;
  max-height: 614px;
  background: #ffffff;
  box-shadow: 0px 9px 54px rgba(0, 0, 0, 0.07),
    0px 2.01px 12.0616px rgba(0, 0, 0, 0.0234994),
    0px 0.6px 3.59106px rgba(0, 0, 0, 0.017426);
  border-radius: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 15px;
  }
`

export const TitleCard = styled(Typography)`
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #1c1f21;
  text-align: center;
`

export const SubtitleCard = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1f21;
  text-align: center;
`

export const AddNewCompany = styled(Button)`
  position: relative;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2968ef;
`
