import { Box, styled } from "@mui/material";

export const FieldSetBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
