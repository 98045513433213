import { memo, useCallback, useEffect } from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAppSettings } from '../../hooks/useAppSettings';
import { useAppSettingsUpdate } from '../../hooks/useAppSettingsUpdate';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { TAppSettings } from '../../models';

export const AppSettingsCard = memo((props: { appId: string }) => {
  const {
    appId,
  } = props;

  const [data, loading, error] = useAppSettings(appId);
  const [updateResult, updateLoading, updateError, update] = useAppSettingsUpdate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback((formValues: TAppSettings) => {
    update(appId, formValues);
  }, [appId, update]);

  useEffect(() => {
    if (updateResult) {
      enqueueSnackbar('App settings updated', { variant: 'success' });
    }
  }, [enqueueSnackbar, updateResult])

  return (
    <Box mb={2}>
      <Card>
        <CardHeader
          title='App settings'
          action={
            loading ? <CircularProgress size={25} /> : null
          }
        />
        <Form
          initialValues={updateResult || data}
          onSubmit={onSubmit}
          render={({handleSubmit, dirty}) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
                <TextField name='onboardingConfig' label='Config' multiline />
                {updateError !== null && <Alert severity='error'>{updateError.toString()}</Alert>}
              </CardContent>
              <CardActions>
                <Button type='submit' disabled={updateLoading || !dirty}>Save</Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    </Box>
  );
});
