import { TokenData } from "../../auth/types";
import { request } from "./request"

export const requestSms = async (phone: string): Promise<{ salt: string }> => {
  const response = await request({
    path: '/api/v1/user-service/request-code',
    method: 'POST',
    body: {
      phone,
    },
  })

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
}

export const authWithCode = async (phone: string, code: string, salt: string): Promise<TokenData> => {
  const response = await request({
    path: '/api/v1/user-service/authuser-with-code',
    method: 'POST',
    body: {
      phone,
      code,
      salt,
    },
  })

  if (response.ok) {
    return response.data.token;
  }

  throw new Error(response.errorMessage);
}