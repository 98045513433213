import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CSSProperties, useEffect, useState } from "react";
import { Content, ImageDialogContent, OpenHandler } from "./styles";
import PDFPreviewer from "../PdfPreview";
import PDFViewer from "../PDFViewer";

export type Props = {
  requestId: string;
  documentId: string;
  fileId: string;
  dataLink: string;
};

enum FileType {
  "video" = "video",
  "PDF" = "application/pdf",
  "image" = "image",
}

export const FileContent = (props: Props) => {
  const { dataLink } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currentFileType, setCurrentFileType] = useState<FileType>();

  useEffect(() => {
    const getContentType = async () => {
      if (dataLink) {
        try {
          const response = await fetch(dataLink);
          const contentType = response.headers.get("content-type");
          if (contentType === "application/pdf") {
            setCurrentFileType(FileType.PDF);
            return;
          }
          if (contentType?.includes("video")) {
            setCurrentFileType(FileType.video);
            return;
          }
          setCurrentFileType(FileType.image);
        } catch (e) {
          console.log("e", e);
        }
      }
    };

    getContentType();
  }, [dataLink]);

  let styles: CSSProperties = {
    width: "50%",
    paddingBottom: currentFileType === FileType.PDF ? 0 : "100%",
    position: "relative",
    backgroundColor: "#ccc",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  let content = null;

  if (dataLink) {
    if (currentFileType === FileType.image)
      styles.backgroundImage = `url(${dataLink})`;
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const allowOpen = Boolean(dataLink);

  return currentFileType !== FileType.video ? (
    <Box sx={styles}>
      {currentFileType === FileType.PDF ? (
        <PDFPreviewer path={dataLink} />
      ) : (
        content && <Content>{content}</Content>
      )}
      {allowOpen && <OpenHandler onClick={handleOpen} />}
      {isOpen && (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                File View
              </Typography>
            </Toolbar>
          </AppBar>
          <ImageDialogContent>
            {currentFileType === FileType.PDF ? (
              <PDFViewer path={dataLink} />
            ) : (
              <img
                src={dataLink}
                alt=''
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            )}
          </ImageDialogContent>
        </Dialog>
      )}
    </Box>
  ) : (
    <video src={dataLink} controls={true} style={{ width: "50%" }}></video>
  );
};
