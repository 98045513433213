import * as React from 'react';
import { memo, FC, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { sanitizeFieldRestProps } from './sanitizeFieldRestProps';
import { BaseViewFieldProps, InjectedViewFieldProps } from './types';

export const TextViewField: FC<TextViewFieldProps> = memo((props) => {
  const { className, record, source, emptyText, ...rest } = props;
  const value = source ? get(record, source) : record;

  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {value != null && typeof value !== 'string'
        ? JSON.stringify(value)
        : value || emptyText}
    </Typography>
  );
});

export interface TextViewFieldProps
  extends BaseViewFieldProps,
    InjectedViewFieldProps,
    Omit<TypographyProps, 'textAlign'> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
}
