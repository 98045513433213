export const numbersRegExp = /[^0-9+]\s*/g;

export const filterInput = (
  value: string,
  regExp: RegExp,
  cb: (value: string) => void
) => {
  let filteredValue = value.replace(regExp, "");
  cb(filteredValue);
};
