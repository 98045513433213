import { FC, ReactNode } from "react";
import { Stack, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ApplicantsIcon, GridIcon, ListIcon } from "../../../MuiIcons";
import { CustomIconButton } from "../../../CustomIconButton";

const icons = [
  {
    image: <GridIcon />,
    alt: "Companies",
    path: "/companies",
  },
  {
    image: <ApplicantsIcon />,
    alt: "Verification requests",
    path: "/verification-requests",
  },
  {
    image: <ListIcon />,
    alt: "Apps",
    path: "/apps",
  },
];

type direction = "column" | "row" | "row-reverse" | "column-reverse";

interface ISideBarIconsProps {
  direction?: direction;
  children?: ReactNode;
}
export const SideBarIcons: FC<ISideBarIconsProps> = ({
  direction = "column",
}) => {
  return (
    <Box>
      <Stack
        direction={direction}
        justifyContent='flex-start'
        alignItems='center'
        spacing={2}
      >
        <Stack
          direction={direction}
          justifyContent='space-between'
          alignItems='center'
          gap='8px'
        >
          {icons.map(({ image, alt, path }) => (
            <CustomIconButton
              key={path}
              aria-label={alt}
              color='primary'
              // @ts-ignore
              component={NavLink}
              to={path}
              disableRipple
            >
              {image}
            </CustomIconButton>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
