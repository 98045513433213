import { snakeCase } from "lodash";
import { TPagingResponse } from "../../../models/PagingResponse";
import { request } from "../../../network/mainClient/request";
import { mapVerificationRequestIn } from "../mappers";
import { TVerificationRequest } from "../models";

export * from "./documents";

export const getVerificationRequestListForCompany = async (
  limit = 10,
  offset = 0,
  companyId: string | null,
  statuses = [],
  query = ""
): Promise<TPagingResponse<TVerificationRequest>> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/company/${companyId}?limit=${limit}&offset=${offset}${statuses
      .map((status) => "&statuses=" + status)
      .join("")}${query && "&query=" + query}`,
    method: "GET",
  });

  if (response.ok) {
    return {
      count: parseInt(response.data.count),
      items: response.data.verificationRequest.map((x: TVerificationRequest) =>
        mapVerificationRequestIn(x)
      ),
    };
  }

  throw new Error(response.errorMessage);
};

export const getVerificationRequestList = async (
  limit = 10,
  offset = 0,
  statuses = [],
  query = ""
): Promise<TPagingResponse<TVerificationRequest>> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request?limit=${limit}&offset=${offset}${statuses
      .map((status) => "&statuses=" + status)
      .join("")}${query && "&query=" + query}`,
    method: "GET",
  });

  if (response.ok) {
    return {
      count: parseInt(response.data.count),
      items: response.data.verificationRequest.map((x: TVerificationRequest) =>
        mapVerificationRequestIn(x)
      ),
    };
  }

  throw new Error(response.errorMessage);
};

export const getVerificationRequest = async (
  id: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}`,
    method: "GET",
  });

  if (response.ok) {
    return mapVerificationRequestIn(response.data.verificationRequest);
  }

  throw new Error(response.errorMessage);
};

export const uploadDocumentData = async (
  documentId: string,
  documentType: string,
  data: any
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/document/${documentId}`,
    method: "POST",
    body: {
      [snakeCase(documentType)]: data || {},
    },
  });

  if (response.ok) {
    return response.data.document;
  }

  throw new Error(response.errorMessage);
};

export const sendToVerificationRequest = async (id: string): Promise<any> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/send-to-verification`,
    method: "POST",
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
export const acceptVerificationRequest = async (id: string): Promise<any> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/verify`,
    method: "POST",
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const declineVerificationRequest = async (id: string): Promise<any> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/decline`,
    method: "POST",
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const requestInformation = async (
  id: string,
  data: any
): Promise<any> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/request-information`,
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const updateDocument = async (data: any): Promise<any> => {
  const response = await request({
    path: `/api/v1/admin-documents/${data.id}/`,
    method: "PATCH",
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const getVerificationRequestStatistic = async (
  id: string
): Promise<any> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request-statistic?verification_request_id=${id}&offset=0&limit=100`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.verificationRequestStatistic;
  }

  throw new Error(response.errorMessage);
};
