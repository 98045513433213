import {
  MenuItem,
  Stack,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { FC, useState } from "react";

export const CustomMultiSelect: FC<{
  data: any[];
  name: string;
  label: string;
  onChange: any;
  values: any;
  valueField?: string;
  nameField?: string;
  MenuProps?: any;
  [x: string]: any;
}> = ({
  data,
  name,
  label,
  onChange,
  values,
  valueField,
  nameField,
  setValue,
  ...other
}) => {
  const [open, setOpen] = useState(false);

  const getValueField = (elem: any) => (valueField ? elem[valueField] : elem);
  const getNameField = (elem: any) => (nameField ? elem[nameField] : elem);

  return (
    <FormControl fullWidth {...other}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant='filled'
        name={name}
        label={label}
        onChange={(e) => onChange(e.target.value)}
        value={values}
        renderValue={() =>
          values.map((value: any) => getNameField(value)).join(", ")
        }
        multiple
        onClick={() => setOpen((prev) => !prev)}
        open={open}
      >
        <Stack alignItems='flex-end'>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            Done
          </Button>
        </Stack>
        {data.map((elem, index) => (
          <MenuItem
            onClick={(e) => e.stopPropagation()}
            key={index}
            value={getValueField(elem)}
          >
            <Checkbox
              size='small'
              checked={values.indexOf(getValueField(elem) as string) > -1}
            />
            {getNameField(elem)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
