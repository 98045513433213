import { useCallback, useEffect } from "react";
import { useVerificationRequestAccept } from "../../hooks/useVerificationRequestAccept";
import { useVerificationRequestDecline } from "../../hooks/useVerificationRequestDecline";
import { ConfirmationDialog } from "../../../ui/components/ConfirmationDialog";

export type Props = {
  onClose: (reason: "cancel" | "accept" | "decline") => void;
  open: boolean;
  isNamesEqual: boolean;
  documentId: string;
  status: string;
  value: "accept" | "decline" | null;
};

export const DocumentReviewDialog = (props: Props) => {
  const { documentId, onClose, open, value, status, isNamesEqual } = props;
  const [acceptResult, acceptLoading, acceptError, acceptRequest] =
    useVerificationRequestAccept();
  const [declineResult, declineLoading, declineError, declineRequest] =
    useVerificationRequestDecline();

  const error = acceptError || declineError;
  const loading = acceptLoading || declineLoading;

  useEffect(() => {
    if ((acceptResult || declineResult) && value) {
      onClose(value);
    }
  }, [acceptResult, declineResult, onClose, value]);

  const handleCancel = useCallback(() => {
    onClose("cancel");
  }, [onClose]);

  const handleOk = useCallback(() => {
    if (value === "accept") {
      acceptRequest(documentId);
    } else if (value === "decline") {
      declineRequest(documentId);
    }
  }, [acceptRequest, declineRequest, value, documentId]);

  return (
    <ConfirmationDialog
      title='Are you sure?'
      description={
        value === "accept"
          ? status === "valid"
            ? isNamesEqual
              ? "After this action, the entry will be approved"
              : "Names form Passport don't match with names from User Information! Are you sure you want to approve?"
            : 'The database screening is not in "Valid" status - some KYC steps might be missing. Are you sure you want to approve?'
          : "After this action, the entry will be rejected"
      }
      titleColor={status === "valid" && isNamesEqual ? "inherit" : "#d32f2f"}
      descriptionColor={
        status === "valid" && isNamesEqual ? "inherit" : "#d32f2f"
      }
      error={error}
      confirmText={value === "accept" ? "Approve" : "Reject"}
      confirmAction={handleOk}
      disabled={loading}
      onClose={handleCancel}
      isOpen={open}
      confirmButtonColor={value === "accept" ? "primary" : "error"}
    />
  );
};
