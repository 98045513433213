import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  ListItemIcon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { BanIcon, ReloadIcon } from "../../../../components/MuiIcons";
import { PanelLayout } from "../PanelLayout";
import { FC } from "react";
import { ActionButton } from "./styles";
import SendIcon from "@mui/icons-material/Send";

const getActions: (status: string) => {
  id: number;
  label: string;
  color: string;
  icon: React.ReactNode;
  subtitle: string;
  actionValue?: string;
}[] = (status) => {
  return [
    status === "NEW"
      ? {
          id: 0,
          label: "Send to Verification",
          subtitle: "If the user forgot to send",
          color: "#2968EF",
          icon: <SendIcon color='primary' />,
          actionValue: "sendToVerify",
        }
      : {
          id: 1,
          label: "Request information",
          subtitle: "If something is lost or missed",
          color: "#2968EF",
          icon: <ReloadIcon />,
          actionValue: "requestInfo",
        },
    {
      id: 2,
      label: "Blacklist",
      subtitle: "If something is unacceptable",
      color: "#1C1F21",
      icon: <BanIcon />,
    },
  ];
};

export const ActionPanel: FC<{
  onAction: (value: string) => void;
  status: string;
  loading: boolean;
}> = ({ onAction, status, loading }) => {
  return (
    <PanelLayout title='Actions'>
      <List>
        {getActions(status).map(
          ({ id, label, color, icon, subtitle, actionValue }, index) => (
            <>
              <ListItem
                disablePadding
                key={id}
                sx={{
                  margin: "8px 0",
                  borderBottom: "1px solid #F4F4F4",
                }}
              >
                {index === 0 && loading ? (
                  <CircularProgress style={{ marginBottom: 14 }} size={30} />
                ) : (
                  <ListItemButton
                    sx={{ margin: "0", padding: "0", flexWrap: "wrap" }}
                    onClick={() => actionValue && onAction(actionValue)}
                  >
                    <ListItemIcon sx={{ minWidth: "0", marginRight: "10px" }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        color: color,
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "22px",
                        letterSpacing: "0.5px",
                      }}
                    />
                    <Typography fontSize={"14px"} mb='1em' width='100%'>
                      {subtitle}
                    </Typography>
                  </ListItemButton>
                )}
              </ListItem>
            </>
          )
        )}
      </List>
      <Stack
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
        width='100%'
        height='100%'
        gap='16px'
      >
        <Box flexGrow={1}>
          <ActionButton
            variant='contained'
            color='error'
            onClick={() => onAction("decline")}
            disabled={status === "NEW"}
          >
            Reject
          </ActionButton>
        </Box>
        <Box flexGrow={1}>
          <ActionButton
            disabled={status === "NEW"}
            variant='contained'
            onClick={() => onAction("accept")}
          >
            Approve
          </ActionButton>
        </Box>
      </Stack>
    </PanelLayout>
  );
};
