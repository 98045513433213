import {
  CircularProgress,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../MuiIcons";
import { Content, Empty, Loader, PageLayoutWrapper, HeaderBox } from "./styles";

const PageLayout = (props: any) => {
  const {
    title,
    actions,
    loading,
    children,
    empty = false,
    footer = null,
    subheader = null,
    backUrl,
    subTitle,
  } = props;

  const navigate = useNavigate();

  const handleToUrl = useCallback(
    (url) => {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate(url);
      }
    },
    [navigate]
  );

  return (
    <PageLayoutWrapper>
      <HeaderBox>
        <Box mt={"50px"} />
        <Toolbar disableGutters>
          <Box flexGrow='1'>
            {title && (
              <Stack flexDirection='row' alignItems='center' gap='16px'>
                {backUrl && (
                  <IconButton
                    onClick={() => handleToUrl(backUrl)}
                    sx={{
                      background: "#DEE0E4",
                      borderRadius: "8px",
                      padding: "6px",
                      marginLeft: "-20px",
                    }}
                  >
                    <BackIcon />
                  </IconButton>
                )}
                <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  spacing={1}
                >
                  <Typography variant='h1'>{title}</Typography>
                  {subTitle && subTitle}
                </Stack>
              </Stack>
            )}
          </Box>
          {actions && actions}
        </Toolbar>
      </HeaderBox>
      <Box m={16} />
      {subheader && (
        <>
          <Toolbar disableGutters>{subheader}</Toolbar>
          <Box m={1} />
        </>
      )}
      <Content>
        {children}
        {empty && !loading && (
          <Empty>
            <Typography variant='body2' color='textSecondary'>
              There is no data
            </Typography>
          </Empty>
        )}
        {loading && (
          <Loader>
            <CircularProgress size={50} />
          </Loader>
        )}
      </Content>
      {footer}
    </PageLayoutWrapper>
  );
};

export default PageLayout;
