import * as React from "react";
import { cloneElement, FC, ReactElement } from "react";
import { TableBody, TableBodyProps } from "@mui/material";

import { DatagridRow, TRowClickFunction } from "./DataGridRow";
import { TId, TRecord } from "../../models";

export const DataGridBody: FC<DatagridBodyProps> = React.forwardRef(
  (
    {
      children,
      className,
      data = [],
      row = <DatagridRow />,
      onRowClick,
      rowStyle,
      selectedIds,
      selectedId,
      isRowSelectable,
      ...rest
    },
    ref
  ) => {
    return (
      <TableBody ref={ref} {...rest}>
        {data.map((record, rowIndex) =>
          cloneElement(
            row,
            {
              id: record.id,
              key: record.id ?? `row${rowIndex}`,
              record,
              onRowClick,
              selectable: !isRowSelectable || isRowSelectable(record),
              selected:
                selectedIds?.includes(record.id) || selectedId === record.id,
              style: rowStyle ? rowStyle(record, rowIndex) : null,
            },
            children
          )
        )}
      </TableBody>
    );
  }
);

export interface DatagridBodyProps extends Omit<TableBodyProps, "classes"> {
  className?: string;
  data?: any[];
  expand?:
    | ReactElement
    | FC<{
        id: TId;
        record: TRecord;
        resource: string;
      }>;
  hasBulkActions?: boolean;
  hover?: boolean;
  onToggleItem?: (id: TId, event: React.TouchEvent | React.MouseEvent) => void;
  record?: TRecord;
  resource?: string;
  row?: ReactElement;
  onRowClick?: TRowClickFunction;
  rowStyle?: (record: TRecord, index: number) => any;
  selectedIds?: TId[];
  selectedId?: TId;
  isRowSelectable?: (record: TRecord) => boolean;
}
