import { styled, Box } from "@mui/system";

export const AppLayoutWrapper = styled(Box)`
  padding-left: 160px;
  padding-right: 60px;
  @media screen and (max-width: 850px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
