import * as React from "react";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { TRecord } from "../../models";

export const DatagridCell = React.forwardRef<
  HTMLTableCellElement,
  DatagridCellProps
>(({ className, field, record, resource, ...rest }, ref) => (
  <TableCell
    align={field?.props.textAlign}
    ref={ref}
    component='th'
    scope='row'
    {...rest}
  >
    {field &&
      React.cloneElement(field, {
        record,
      })}
  </TableCell>
));

export interface DatagridCellProps extends TableCellProps {
  field?: JSX.Element;
  record?: TRecord;
  resource?: string;
}

// What? TypeScript loses the displayName if we don't set it explicitly
DatagridCell.displayName = "DatagridCell";
