import { Typography, Box } from '@mui/material'
import { FC } from 'react'

export const PanelLayout: FC<{
  title: string | number
  after?: string | number
  children: React.ReactNode
}> = ({ title, children, after }) => (
  <Box
    sx={{
      marginBottom: '24px',
      padding: '20px 32px',
      background: '#ffffff',
      borderRadius: '14px',
    }}
  >
    <Box>
      <Typography
        variant='h5'
        sx={{
          fontWeight: '800',
          letterSpacing: '0.5px',
          color: '#1C1F21',
        }}
      >
        {title}
        {after && <span style={{ color: '#8D959F' }}>{after}</span>}
      </Typography>
    </Box>
    {children}
  </Box>
)
