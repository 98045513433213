import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';

export const UnauthorizedContainer = () => {
  const { authenticated } = useAuth();
  
  if (authenticated) {
    return <Navigate to="/" />
  }

  return (
    <Outlet />
  );
}
