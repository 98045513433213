import { Box, Typography } from '@mui/material'
import { PanelLayout } from '../PanelLayout'

export const DuplicatesPanel = () => (
  <PanelLayout title='Duplicates' after='(0)'>
    <Box marginTop='16px'>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#8D959F',
        }}
      >
        No duplicates were found
      </Typography>
    </Box>{' '}
  </PanelLayout>
)
