import { useCallback, useEffect, useState } from 'react';
import { TVerificationRequestStatistic } from '../models';
import { getVerificationRequestStatistic } from '../network';

export type TResult = null | TVerificationRequestStatistic[];

export const useVerificationRequestStatistic = (
  requestId: string
): [TResult, boolean, Error | null, () => void] => {
  const [result, setResult] = useState<TResult>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getVerificationRequestStatistic(requestId);
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [requestId]);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
