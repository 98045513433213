import { useCallback, useState } from 'react';
import { TSortData } from '../models';

export type TSortDirection = 'asc' | 'desc';

export type TListParamsState = {
  query: string;
  from: number;
  size: number;
  sort: TSortData | null;
  setQuery: (query: string) => void;
  setFrom: (from: number) => void;
  setSize: (size: number) => void;
  setSort: (sortData: TSortData | null) => void;
};

export const useListParamsState = (): TListParamsState => {
  const [query, setQuery] = useState('');
  const [from, setFrom] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState<TSortData | null>(null);

  const changeQuery = useCallback(
    (query) => {
      setQuery(query);
      setFrom(0);
    },
    [setQuery, setFrom]
  );

  return {
    query,
    from,
    size,
    sort,
    setQuery: changeQuery,
    setFrom,
    setSize,
    setSort,
  };
};
