import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../../auth/AuthProvider';
import { mainTheme } from '../../theme/mainTheme';
import { RootLayout } from '../../components/RootLayout';
import { NavigationContainer } from '../NavigationContainer';

export const RootContainer = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <SnackbarProvider>
        <Router>
          <RootLayout>
            <AuthProvider>
              <NavigationContainer />
            </AuthProvider>
          </RootLayout>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
