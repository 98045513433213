import { styled, Button } from "@mui/material";

export const ActionButton = styled(Button)`
  padding: 16px 24px;
  width: 100%;
  height: 54px;
  box-shadow: none;
  border-radius: 12px;
  text-transform: capitalize;
  font-weight: 700;
`;
