import { List, Stack } from "@mui/material";
import {
  ApprovedIcon,
  CancelIcon,
  FlameIcon,
  PendingIcon,
} from "../../../../components/MuiIcons";
import { PanelLayout } from "../PanelLayout";
import { FC } from "react";
import { LabelText, ValueText } from "./styles";

export const externalStatusModel = {
  valid: {
    color: "#2CC369",
    icon: <ApprovedIcon viewBox='0 0 20 20' />,
  },
  invalid: {
    color: "#CF0000",
    icon: <CancelIcon viewBox='0 0 20 20' />,
  },
  processing: {
    color: "#D2B102",
    icon: <PendingIcon />,
  },
  pending: {
    color: "#D2B102",
    icon: <PendingIcon />,
  },
  new: {
    color: "#2968EF",
    icon: <FlameIcon viewBox='0 0 20 20' />,
  },
};

export const DatabaseScreeningPanel: FC<{ data: any }> = ({ data }) => {
  if (!data) return null;

  return (
    <PanelLayout title='Database screening'>
      <List>
        <Stack direction='row' spacing={2}>
          <LabelText>Current status</LabelText>
          {data.externalStatus && (
            <ValueText
              sx={{
                color:
                  externalStatusModel[
                    data.externalStatus as keyof typeof externalStatusModel
                  ]?.color,
              }}
            >
              {
                externalStatusModel[
                  data.externalStatus as keyof typeof externalStatusModel
                ]?.icon
              }
              {data.externalStatus}
            </ValueText>
          )}
        </Stack>
        <Stack direction='row' spacing={2} mt={2}>
          <LabelText>Last check date</LabelText>
          <ValueText>{new Date(data.syncTime).toUTCString()}</ValueText>
        </Stack>
        {data.declineReason?.length > 0 && (
          <Stack direction='row' spacing={2} mt={2}>
            <LabelText>Decline reason</LabelText>
            <ValueText>
              {data.declineReason
                .map((reason: string) => reason.replace(/_/g, " "))
                .join(", ")}
            </ValueText>
          </Stack>
        )}
      </List>
    </PanelLayout>
  );
};
