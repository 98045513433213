import { FC } from 'react'
import { AppHeader } from '../AppHeader'

export const AuthorizedLayout: FC = ({
  children,
}) => {
  return (
    <>
      <AppHeader />
      {children}
    </>
  )
}
