import { useCallback, useState } from 'react';
import { requestInformation } from '../network';

export const useVerificationRequestInformation = (
  id?: string,
  textMessage?: string
): [
  any | null,
  boolean,
  Error | null,
  (requestId: string, message: string) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (requestId?: string, message?: string) => {
      setIsLoading(true);
      setError(null);

      requestId = requestId || id;
      message = message || textMessage;

      if (!requestId) {
        setError(new Error('ID is not provided'));
      }
      if (!message) {
        setError(new Error('Message is empty'));
      }

      try {
        const response = await requestInformation(requestId!, {
          message: message,
        });
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [id, textMessage]
  );

  return [result, isLoading, error, request];
};
