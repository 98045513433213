import { FC, useEffect, useState } from 'react';
import { SearchIcon } from '../MuiIcons';
import InputAdornment from '@mui/material/InputAdornment';
import { InputStyled } from './styles';
import useDebounce from '../../modules/ui/hooks/useDebounce';

export const SearchInput: FC<{ onChangeValue: (value: string) => void }> = ({
  onChangeValue,
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchValue = useDebounce(inputValue, 500);

  useEffect(() => {
    onChangeValue(debouncedSearchValue);
  }, [debouncedSearchValue, onChangeValue]);

  return (
    <InputStyled
      placeholder='Search text'
      value={inputValue}
      onChange={(event: any) => setInputValue(event?.target?.value)}
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
