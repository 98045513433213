import { Select } from "mui-rff";
import { FilledTextArea } from "../../../../components/FilledTextArea";

const professional_status = [
  {
    value: "Employed",
    label: "Employed",
  },
  {
    value: "Unemployed",
    label: "Unemployed",
  },
  {
    value: "Retired",
    label: "Retired",
  },
  {
    value: "Self-employed",
    label: "Self-employed",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const Occupation = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
}) => {
  const { onChange, fields, name, label, disabled } = props;
  if (name === "professional_status") {
    return (
      <Select
        variant='filled'
        name={name}
        label={label}
        data={professional_status}
        value={fields[name] ?? ""}
        inputProps={{ onChange }}
        disabled={disabled}
      />
    );
  } else {
    return (
      <FilledTextArea
        name={name}
        label={label}
        inputProps={{ onChange }}
        value={fields[name] ?? ""}
        disabled={disabled}
      />
    );
  }
};
