import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';
import { AuthorizedLayout } from '../../components/AuthorizedLayout';

export const AuthorizedContainer = () => {
  const { authenticated } = useAuth();
  
  if (!authenticated) {
    return <Navigate to="/auth" />
  }

  return (
    <AuthorizedLayout>
      <Outlet />
    </AuthorizedLayout>
  );
}
